@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
  font-family: "Comfortaa", cursive;
  font-family: "Poppins", sans-serif;
}

html {
  font-size: 62.5%;
}

* {
  box-sizing: border-box;
}

.css-67l5gl::before {
  height: 0px !important;
}
