.nova-comp-my_profile-main-container {
  width: 100%;
  background: #ffeff7;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6.5rem;
  padding-bottom: 2.1rem;
}

.nova-comp-my_profile-main-main-container {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

@media screen and (max-width: 750px) {
  .nova-comp-my_profile-main-main-container {
    align-items: center;
  }
}
