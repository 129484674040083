.nova-login_main_view {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: scroll; */
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .update-password-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 34%;
    justify-content: space-between;
  }
  
  .update-password-div img{
    height: 40px;
    width: 40px;
    /* margin-top: 20px; */
    cursor: pointer;
  }