.nova-dashboard-single_service_view {
  display: flex;
  flex-direction: column;
  margin-right: 3.6rem;
  width: 43.6rem;
  cursor: pointer;
}

.nova-dashboard-single_service_view img {
  width: 43.6rem;
  /* padding-left: -5rem !important; */
  height: 35.6rem;
  object-fit: cover;
  /* border: 2px solid green; */
}

.nova-dashboard-single_service_title_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.6rem;
  margin-bottom: 2rem;
}

.nova-dashboard-single_service_view h2 {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #FFFFFF;
}

.nova-dashboard-single_service_view h3 {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #FFFFFF;
}

.nova-dashboard-single_service_view h4 {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #FFFFFF;
  padding-bottom: 4rem;
}

.nova-dashboard-single_service_border_view {
  display: flex;
  width: 14.9rem;
  margin-bottom: 5.6rem;
  border: 5px solid #FFFFFF;
}

@media screen and (max-width:1440px) {
  .nova-dashboard-single_service_view {
    margin-right: 2.6rem;
    width: 36.6rem;
  }

  .nova-dashboard-single_service_view img {
    width: 36.6rem;
    height: 28.6rem;
  }

  .nova-dashboard-single_service_title_view {
    margin-top: 2.2rem;
    margin-bottom: 1.6rem;
  }

  .nova-dashboard-single_service_view h2 {
    font-size: 2rem;
    line-height: 2.3rem;
  }

  .nova-dashboard-single_service_view h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .nova-dashboard-single_service_view h4 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding-bottom: 3rem;
  }

  .nova-dashboard-single_service_border_view {
    width: 12.9rem;
    margin-bottom: 4.6rem;
    border: 3px solid #FFFFFF;
  }
}


@media screen and (max-width:1200px) {
  .nova-dashboard-single_service_view {
    margin-right: 2.6rem;
    width: 25.6rem;
  }

  .nova-dashboard-single_service_view img {
    width: 25.6rem;
    height: 26.6rem;
  }

  .nova-dashboard-single_service_title_view {
    margin-top: 34rem;
    /* margin-top: 3rem; */
    margin-bottom: 1.4rem;
  }

  .nova-dashboard-single_service_view h2 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-dashboard-single_service_view h3 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-dashboard-single_service_view h4 {
    font-size: 1.4rem;
    line-height: 2rem;
    padding-bottom: 2rem;
  }

  .nova-dashboard-single_service_border_view {
    width: 10.9rem;
    margin-bottom: 4.6rem;
    border: 3px solid #FFFFFF;
  }
}

@media screen and (max-width:360px) {
  .nova-dashboard-single_service_view {
    margin-right: .6rem;
    width: 20.6rem;
    display: flex;
    flex-direction: column;
  }

  .nova-dashboard-single_service_view img{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -100px !important;
    height: 21.6rem;
    width: 21.6rem;

  }

  .nova-dashboard-single_service_title_view {
    margin-top: 12rem !important;
    margin-bottom: 1.4rem;
  }

  .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 15px;
    z-index: 1000;
    border: 0;
    background: rgba(0,0,0,0.5);
    min-width: 30px;
    min-height: 30px;
    opacity: 1;
    cursor: pointer;
}

.nova-dashboard-our_services_and_specials_top_view{
  padding-left: 2.6rem !important;
  /* width: auto; */
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
}

.react-multi-carousel-track li {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
    width: 330px!important;
}
  
}