.nova-dashboard-main_container {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
  overflow: visible;
}

.nova-dashboard-container {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}

.nova-dashboard-banner_top_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 16.6rem 6.1rem 16.6rem;
  align-items: flex-start;
  background-image: url('../../assets/homePageBanner.png');
  background-size: 100% 100%; /* Image will cover 100% of width and height */
  background-repeat: no-repeat; /* Prevents image from repeating */
}


.nova-dashboard-banner_detail_view {
  display: flex;
  flex-direction: column;
  width: 40%;
  padding-top: 8.8rem;
}

.nova-dashboard-banner_detail_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.4rem;
  color: #292929;
  padding-bottom: 3rem;
}

.nova-dashboard-banner_detail_view h5 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #292929;
  padding-bottom: 6rem;
}

.nova-dashboard-banner_detail_view h3 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #ee509c;
  padding-bottom: 2.3rem;
}

.nova-dashboard-banner_percantages_view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.nova-dashboard-banner_percantages_view div {
  display: flex;
  flex-direction: column;
  margin-right: 4.5rem;
  align-items: center;
}

.nova-dashboard-banner_percantages_view h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.8rem;
  color: #292929;
  padding-bottom: 0.6rem;
}

.nova-dashboard-banner_percantages_view h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 109.5%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292929;
  width: 11.8rem;
  text-align: center;
}

.nova-dashboard-banner_image_view {
  display: flex;
  flex: 1;
  height: 100%;
  justify-content: flex-end;
  padding-top: 6.7rem;
}

.nova-dashboard-banner_image_view img {
  width: 44.1rem;
  height: 49.1rem;
  object-fit: contain;
}

.react-multi-carousel-track li {
  /* width: 470px!important; */
  width: 470px;
}

.nova-dashboard-our_services_and_specials_top_view {
  display: flex;
  flex-direction: column;
  padding-top: 5rem;
  padding-left: 16.6rem;
  background-color: #ee509c;
}

.nova-dashboard-our_services_top_view {
  display: flex;
  flex-direction: column;
}

.nova-dashboard-our_services_and_specials_top_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #ffffff;
  padding-bottom: 1.8rem;
}

.nova-dashboard-our_services_view {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.nova-dashboard-our_services_view::-webkit-scrollbar {
  width: 0 !important;
}

.nova-dashboard-map_top_view {
  display: flex;
  background-color: white;
  padding: 0rem 16.6rem;
  padding-bottom: 3rem;  
}

.nova-dashboard-location_text_view {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2.4rem 16.6rem 1.6rem 16.6rem;
}

.map-img img{
  width: 100%;
  height: 100%;
}

.nova-dashboard-location_text_view h6 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 7.4rem;
  color: #000000;
}

.nova-dashboard-map_top_view div {
  width: 139.6rem;
  height: 71.8rem;
  object-fit: contain;
  
}

.nova-dashboard-about_me_top_view {
  display: flex;
  background-color: white;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 16.6rem 6.4rem 16.6rem;
}

.nova-dashboard-about_me_detail_view {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.nova-dashboard-about_me_know_text h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 8rem;
  line-height: 8.9rem;
  color: #292929;
}

.nova-dashboard-about_me_know_text span {
  -webkit-text-stroke: 1.5px #292929;
  color: #ffffff;
}

.nova-dashboard-about_me_dont_find_text h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 6.4rem;
  line-height: 7.1rem;
  color: #292929;
  padding-bottom: 3.5rem;
}

.nova-dashboard-about_me_dont_find_text span {
  background-color: #ed509c;
  padding: 5px;
  color: #ffffff;
}

.nova-dashboard-about_me_detail_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #3d3d3d;
  width: 90%;
}

.nova-dashboard-about_me_clients_top_view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: 2rem;
}

.nova-dashboard-about_me_clients_top_view div {
  display: flex;
  flex-direction: column;
  margin-right: 5rem;
}

.nova-dashboard-about_me_clients_top_view h4 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 6rem;
  line-height: 146.47%;
  color: #292929;
}

.nova-dashboard-about_me_clients_top_view h5 {
  font-family: "Comfortaa";
  font-weight: 400;
  font-size: 2.3rem;
  line-height: 3.3rem;
  color: #292929;
}

.nova-dashboard-about_me_image_view {
  display: flex;
  flex: 1;
}

.nova-dashboard-about_me_image_view img {
  width: 100%;
  height: 95%;
  object-fit: cover;
}

.nova-dashboard-reviews_top_view {
  display: flex;
  flex-direction: column;
  padding: 0rem 0rem 6.4rem 16.6rem;
}

.nova-dashboard-reviews_top_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.4rem;
  color: #000000;
  padding-bottom: 5rem;
}

.nova-dashboard-reviews_view {
  display: flex;
  flex-direction: row;
  overflow: scroll;
}

.nova-dashboard-reviews_view::-webkit-scrollbar {
  width: 0 !important;
}

.nova-dashboard-single_review {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  margin-right: 3.4rem;
}

.nova-dashboard-single_review div {
  display: flex;
  flex-direction: column;
  width: 30rem;
}

.nova-dashboard-single_review img {
  width: 15.3rem;
  height: 15.3rem;
  object-fit: cover;
  border-radius: 1.6rem;
  margin-right: 4.2rem;
}

.nova-dashboard-single_review p {
  font-family: "poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #383838;
}

.nova-dashboard-questions_top_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0rem 16.6rem 1rem 16.6rem;
}

.nova-dashboard-questions_view {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.nova-dashboard-questions_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #292929;
  padding-bottom: 9.1rem;
}

.nova-dashboard-single_question_view {
  display: flex;
  flex-direction: column;
}

.nova-dashboard-single_question_title_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.8rem;
}

.nova-dashboard-single_question_title_view h3 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #292929;
  padding-left: 1.5rem;
}

.nova-dashboard-single_question_title_view img {
  width: 3.4rem;
  height: 3.4rem;
  object-fit: contain;
  cursor: pointer;

  transition-duration: 0.8s;
  transition-property: transform;
  /* overflow: hidden; */
}

.nova-dashboard-single_question_title_view img:hover {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}
.nova-dashboard-single_question_divider {
  display: flex;
  border: 1px solid #3d3d3d;
  margin-bottom: 4rem;
}

.nova-dashboard-single_question_view-h4 {
  animation: growDon 3s ease-in forwards;
  transition-duration: 3s;
  transition-timing-function: linear;
  width: 70%;
}
.nova-dashboard-single_question_view-h4 h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #3d3d3d;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  animation: growDown 600ms ease-out both;
}
@keyframes growDown {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/* .nova-dashboard-single_question_view h4:hover {
  background-color: red;
} */

.nova-dashboard-questions_image_view {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.nova-dashboard-questions_image_view img {
  width: 59.2rem;
  height: 56.3rem;
  object-fit: contain;
}

.nova-dashboard-team_members_top_view {
  display: flex;
  flex-direction: column;
  padding-left: 16.6rem;
  padding-bottom: 12.3rem;
}

.nova-dashboard-team_members_top_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #171d1c;
  padding-bottom: 3.8rem;
}

.nova-dashboard-team_members_view {
  display: flex;
  flex-direction: row;
  overflow: scroll;
  align-items: center;
}

.nova-dashboard-team_members_view::-webkit-scrollbar {
  width: 0 !important;
}

.nova-dashboard-single_member_view {
  display: flex;
  flex-direction: column;
  width: 38rem;
  margin-right: 5rem;
}

.nova-dashboard-single_member_view img {
  width: 38rem;
  height: 37.1rem;
  object-fit: cover;
  margin-bottom: 4.5rem;
}

.nova-dashboard-single_member_detail_view {
  display: flex;
  flex-direction: row;
}

.nova-dashboard-single_member_detail_view_divider {
  display: flex;
  border: 2px solid #000000;
  margin-right: 2.8rem;
  margin-bottom: 2rem;
}

.nova-dashboard-single_member_detail_view h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  padding-bottom: 1rem;
  color: #000000;
}

.nova-dashboard-single_member_detail_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #686161;
}

.nova-dashboard-team_members_view p {
  font-size: 2.2rem;
  font-family: "Poppins";
  font-weight: 400;
  color: #686161;
}

.nova-dashboard-banner_detail_view > h1,h5 {
  /* color: white !important; */
  color: white;
}

.nova-dashboard-banner_percantages_view > div > *{
  color: white !important;
}

@media screen and (max-width: 1440px) {
  .nova-dashboard-map_top_view{
    width: 37% !important;
    height: 37% !important;
  }
  /* .nova-dashboard-location_text_view{} */
}
@media screen and (max-width: 1440px) {
  .nova-dashboard-banner_top_view {
    padding: 0rem 12.6rem 4.1rem 12.6rem;
  }

  .nova-dashboard-banner_detail_view {
    padding-top: 12.8rem;
  }

  .nova-dashboard-banner_detail_view h1 {
    font-size: 4rem;
    line-height: 4.8rem;
    padding-bottom: 2rem;
  }

  .nova-dashboard-banner_detail_view h5 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding-bottom: 5rem;
  }

  .nova-dashboard-banner_detail_view h3 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    padding-bottom: 2rem;
  }

  .nova-dashboard-banner_percantages_view div {
    margin-right: 4rem;
  }

  .nova-dashboard-banner_percantages_view h2 {
    font-size: 4rem;
    line-height: 5rem;
  }

  .nova-dashboard-banner_percantages_view h4 {
    font-size: 1.6rem;
    line-height: 100.5%;
    width: 9.8rem;
  }

  .nova-dashboard-banner_image_view {
    padding-top: 3.7rem;
  }

  .nova-dashboard-banner_image_view img {
    width: 40.1rem;
    height: 45.1rem;
  }

  .nova-dashboard-our_services_and_specials_top_view {
    padding-top: 4rem;
    padding-left: 12.6rem;
  }

  .nova-dashboard-our_services_and_specials_top_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    padding-bottom: 2rem;
  }

  .nova-dashboard-map_top_view {
    padding: 1rem 12.6rem;
    padding-bottom: 3rem;
  }

  .nova-dashboard-location_text_view {
    padding: 2rem 12.6rem;
  }

  .nova-dashboard-map_top_view div {
    width: 119.6rem;
    height: 51.8rem;
  }

  .nova-dashboard-about_me_top_view {
    padding: 0rem 12.6rem 3.4rem 12.6rem;
  }

  .nova-dashboard-about_me_know_text h1 {
    font-size: 6rem;
    line-height: 6.9rem;
    color: #292929;
  }

  .nova-dashboard-about_me_know_text span {
    -webkit-text-stroke: 1.5px #292929;
    color: #ffffff;
  }

  .nova-dashboard-about_me_dont_find_text h2 {
    font-size: 4.4rem;
    line-height: 5.1rem;
    padding-bottom: 2.5rem;
  }

  .nova-dashboard-about_me_dont_find_text span {
    padding: 3px;
  }

  .nova-dashboard-about_me_detail_view h3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    width: 80%;
  }

  .nova-dashboard-about_me_clients_top_view div {
    margin-right: 4rem;
  }

  .nova-dashboard-about_me_clients_top_view h4 {
    font-size: 4rem;
    line-height: 126.47%;
  }

  .nova-dashboard-about_me_clients_top_view h5 {
    font-size: 2rem;
    line-height: 3rem;
  }

  .nova-dashboard-about_me_image_view img {
    height: 85%;
  }

  .nova-dashboard-reviews_top_view {
    padding: 0rem 0rem 4.4rem 12.6rem;
  }

  .nova-dashboard-reviews_top_view h1 {
    font-size: 4rem;
    line-height: 4.6rem;
    padding-bottom: 3rem;
  }

  .nova-dashboard-location_text_view h6 {
    font-size: 4rem;
    line-height: 7.6rem;
  }

  .nova-dashboard-single_review {
    margin-right: 4.4rem;
  }

  .nova-dashboard-single_review img {
    width: 12.3rem;
    height: 12.3rem;
    border-radius: 1.2rem;
    margin-right: 3.2rem;
  }

  .nova-dashboard-single_review p {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-dashboard-single_review div {
    width: 24.8rem;
  }

  .nova-dashboard-questions_top_view {
    padding: 0rem 12.6rem 1rem 12.6rem;
  }

  .nova-dashboard-questions_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    padding-bottom: 6.1rem;
  }

  .nova-dashboard-single_question_title_view h3 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-dashboard-single_question_title_view img {
    width: 3rem;
    height: 3rem;
  }

  .nova-dashboard-single_question_divider {
    margin-bottom: 3rem;
  }

  .nova-dashboard-single_question_view h4 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-dashboard-questions_image_view img {
    width: 49.2rem;
    height: 46.3rem;
  }

  .nova-dashboard-team_members_top_view {
    padding-left: 12.6rem;
    padding-bottom: 8.3rem;
  }

  .nova-dashboard-team_members_top_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    padding-bottom: 2.8rem;
  }

  .nova-dashboard-single_member_view {
    width: 28rem;
    margin-right: 4rem;
  }

  .nova-dashboard-single_member_view img {
    width: 28rem;
    height: 27.1rem;
    margin-bottom: 3.5rem;
  }

  .nova-dashboard-single_member_detail_view_divider {
    margin-right: 2rem;
  }

  .nova-dashboard-single_member_detail_view h2 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .nova-dashboard-single_member_detail_view h3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #686161;
  }
}

@media screen and (max-width: 1200px) {
  .nova-dashboard-banner_detail_view {
    padding-top: 10.8rem;
  }

  .nova-dashboard-banner_detail_view h1 {
    font-size: 3.6rem;
    line-height: 4.4rem;
  }

  .nova-dashboard-banner_detail_view h5 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding-bottom: 4rem;
  }

  .nova-dashboard-banner_detail_view h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-dashboard-banner_percantages_view div {
    margin-right: 3rem;
  }

  .nova-dashboard-banner_percantages_view h2 {
    font-size: 3.6rem;
    line-height: 4.6rem;
  }

  .nova-dashboard-banner_percantages_view h4 {
    font-size: 1.4rem;
    line-height: 90.5%;
    width: 7.8rem;
  }

  .nova-dashboard-banner_image_view {
    padding-top: 2.7rem;
  }

  .nova-dashboard-banner_image_view img {
    width: 30.1rem;
    height: 35.1rem;
  }

  .nova-dashboard-our_services_and_specials_top_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-dashboard-map_top_view div {
    width: 100%;
    height: 51.8rem;
  }

  .nova-dashboard-about_me_know_text h1 {
    font-size: 5rem;
    line-height: 5.9rem;
  }

  .nova-dashboard-about_me_dont_find_text h2 {
    font-size: 4rem;
    line-height: 4.5rem;
  }

  .nova-dashboard-about_me_dont_find_text span {
    padding: 2px;
  }

  .nova-dashboard-about_me_detail_view h3 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-dashboard-about_me_clients_top_view div {
    margin-right: 3rem;
  }

  .nova-dashboard-about_me_clients_top_view h4 {
    font-size: 3.6rem;
    line-height: 120.47%;
  }

  .nova-dashboard-about_me_clients_top_view h5 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-dashboard-about_me_image_view img {
    height: 90%;
  }

  .nova-dashboard-reviews_top_view h1 {
    font-size: 3.6rem;
    line-height: 4rem;
  }

  .nova-dashboard-location_text_view h6 {
    font-size: 3.6rem;
    line-height: 4rem;
  }

  .nova-dashboard-single_review {
    margin-right: 4rem;
  }

  .nova-dashboard-single_review img {
    width: 10.3rem;
    height: 10.3rem;
    border-radius: 1rem;
    margin-right: 3rem;
  }

  .nova-dashboard-single_review p {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .nova-dashboard-single_review div {
    width: 24.8rem;
  }

  .nova-dashboard-questions_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    padding-bottom: 5.1rem;
  }

  .nova-dashboard-single_question_title_view h3 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-dashboard-single_question_title_view img {
    width: 2rem;
    height: 2rem;
  }

  .nova-dashboard-single_question_divider {
    margin-bottom: 2rem;
  }

  .nova-dashboard-single_question_view h4 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .nova-dashboard-questions_image_view img {
    width: 39.2rem;
    height: 34.3rem;
  }

  .nova-dashboard-team_members_top_view {
    padding-left: 10.6rem;
    padding-bottom: 6.3rem;
  }

  .nova-dashboard-team_members_top_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    padding-bottom: 2.4rem;
  }

  .nova-dashboard-single_member_view {
    width: 25rem;
    margin-right: 3rem;
  }

  .nova-dashboard-single_member_view img {
    width: 25rem;
    height: 24.1rem;
  }

  .nova-dashboard-single_member_detail_view_divider {
    margin-right: 2rem;
  }

  .nova-dashboard-single_member_detail_view h2 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-dashboard-single_member_detail_view h3 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #686161;
  }
}

@media screen and (max-width: 1000px) {
  .nova-dashboard-banner_top_view {
    padding: 0rem 8.6rem 3.1rem 8.6rem;
  }

  .nova-dashboard-our_services_and_specials_top_view {
    padding-left: 8.6rem;
  }

  .nova-dashboard-map_top_view {
    padding: 1rem 8.6rem;
    padding-bottom: 3rem;
  }

  .nova-dashboard-location_text_view {
    padding: 2rem 8.6rem;
  }

  .nova-dashboard-about_me_top_view {
    padding: 0rem 8.6rem 3.4rem 8.6rem;
  }

  .nova-dashboard-reviews_top_view {
    padding: 0rem 0rem 3.4rem 8.6rem;
  }

  .nova-dashboard-questions_top_view {
    padding: 0rem 8.6rem 1rem 8.6rem;
  }

  .nova-dashboard-team_members_top_view {
    padding-left: 8.6rem;
  }
}

@media screen and (max-width: 900px) {
  .nova-dashboard-banner_top_view {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nova-dashboard-banner_detail_view {
    width: 100%;
    padding-top: 5.8rem;
  }

  .nova-dashboard-about_me_top_view {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nova-dashboard-about_me_detail_view {
    width: 100%;
  }

  .nova-dashboard-about_me_image_view {
    margin-top: 2rem;
  }

  .nova-dashboard-about_me_image_view img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .nova-dashboard-questions_top_view {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nova-dashboard-questions_view {
    width: 100%;
  }

  .nova-dashboard-questions_image_view {
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
  }

  .nova-dashboard-questions_image_view img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .nova-dashboard-about_me_detail_view h3 {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .nova-dashboard-banner_top_view {
    padding: 0rem 3.6rem 3.1rem 3.6rem;
  }

  .nova-dashboard-our_services_and_specials_top_view {
    padding-left: 3.6rem;
  }

  .nova-dashboard-map_top_view {
    padding: 1rem 3.6rem;
    padding-bottom: 2rem;
  }

  .nova-dashboard-location_text_view {
    padding: 2rem 3.6rem;
  }

  .nova-dashboard-about_me_top_view {
    padding: 0rem 3.6rem 3.4rem 3.6rem;
  }

  .nova-dashboard-reviews_top_view {
    padding: 0rem 0rem 3.4rem 3.6rem;
  }

  .nova-dashboard-questions_top_view {
    padding: 0rem 3.6rem 1rem 3.6rem;
  }

  .nova-dashboard-team_members_top_view {
    padding-left: 3.6rem;
  }
}

/* .nova-dashboard-map_top_view{
  width: 100% !important;
} */

@media screen and (max-width: 470px) {
  .nova-dashboard-banner_detail_view {
    padding: 1rem 1rem 1rem .5rem;
  }
  
  .nova-dashboard-banner_detail_view h1{
    padding-bottom: 1rem 1rem 0rem .5rem;
  }
}
@media screen and (max-width: 470px) {
  .nova-dashboard-team_members_view p {
    font-size: 1.8rem;
    padding-right: 1rem;
  }
  .nova-dashboard-banner_detail_view h1 {
    font-size: 3rem;
    line-height: 3rem;
    padding-bottom: 0.5rem;
    color: white;
  }
  
  .nova-dashboard-banner_detail_view h5 {
    font-size: 1.2rem;
    line-height: 1.9rem;
    padding-bottom: 2rem;
    text-align: justify;
    color: white;
  }

  .nova-dashboard-banner_detail_view h3 {
    font-size: 2rem;
    line-height: 2.4rem;
    padding-bottom: 0.5rem;
  }

  .nova-dashboard-banner_percantages_view {
    flex-wrap: wrap;
  }

  .nova-dashboard-banner_percantages_view div {
    margin-right: 2rem;
    margin-bottom: 1rem;
  }

  .nova-dashboard-banner_percantages_view h2 {
    font-size: 2.8rem;
    line-height: 3rem;
    color: white;
    /* padding-bottom: 0rem; */
  }

  .nova-dashboard-banner_percantages_view h4 {
    font-size: 1.2rem;
    line-height: 85.5%;
    width: 7rem;
    color: white;
  }

  .nova-dashboard-banner_image_view {
    padding-top: 2.2rem;
  }

  .nova-dashboard-banner_image_view img {
    width: 100%;
    height: 100%;
  }

  .nova-dashboard-our_services_and_specials_top_view h1 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-dashboard-map_top_view div {
    width: 100%;
    height: 40.8rem;
  }

  .nova-dashboard-about_me_know_text h1 {
    font-size: 4rem;
    line-height: 4.9rem;
  }

  .nova-dashboard-about_me_dont_find_text h2 {
    font-size: 4rem;
    line-height: 4.5rem;
  }

  .nova-dashboard-about_me_detail_view h3 {
    font-size: 1.2rem;
    line-height: 2rem;
  }

  .nova-dashboard-about_me_clients_top_view {
    flex-wrap: wrap;
  }

  .nova-dashboard-about_me_clients_top_view div {
    margin-right: 2rem;
    margin-bottom: 2rem;
  }

  .nova-dashboard-about_me_clients_top_view h4 {
    font-size: 3.2rem;
    line-height: 100.47%;
  }

  .nova-dashboard-about_me_clients_top_view h5 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .nova-dashboard-about_me_image_view img {
    height: 100%;
  }

  .nova-dashboard-reviews_top_view h1 {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  .nova-dashboard-location_text_view h6 {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  .nova-dashboard-single_review {
    margin-right: 3rem;
    margin-left: 3rem;
  }

  .nova-dashboard-single_review img {
    width: 8.3rem;
    height: 8.3rem;
    border-radius: 0.8rem;
    margin-right: 3rem;
  }

  .nova-dashboard-single_review p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .nova-dashboard-single_review div {
    width: 22.8rem;
  }

  .nova-dashboard-questions_view h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    padding-bottom: 3.1rem;
  }

  .nova-dashboard-single_question_title_view h3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .nova-dashboard-single_question_title_view img {
    width: 1.6rem;
    height: 1.6rem;
  }

  .nova-dashboard-single_question_divider {
    margin-bottom: 2rem;
  }

  .nova-dashboard-single_question_view h4 {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }

  .nova-dashboard-team_members_top_view h1 {
    font-size: 2rem;
    line-height: 2.4rem;
    padding-bottom: 2.2rem;
  }

  .nova-dashboard-single_member_view {
    width: 20rem;
    margin-right: 2rem;
  }

  .nova-dashboard-single_member_view img {
    width: 20rem;
    height: 20.1rem;
  }

  .nova-dashboard-single_member_detail_view_divider {
    margin-right: 2rem;
  }

  .nova-dashboard-single_member_detail_view h2 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-dashboard-single_member_detail_view h3 {
    font-size: 1.2rem;
    line-height: 2.2rem;
    color: #686161;
  }
}


@media screen and (max-width: 360px) {
  .nova-dashboard-main_container {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    height: 100vh;
    overflow: visible;
}   

.nova__navbar-links-insta_text_view img {
  margin-left: 0px !important;
}

.nova__navbar-menu_container {
  top: 2.5rem;
  padding: 2.2rem;
  margin-top: 1.4rem;
  min-width: 20rem;
}

.nova__navbar-links_book_now_button {
  display: flex;
  width: 10rem;
  height: 4.3rem;
  background: #292929;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0.2rem 3.8rem 0.2rem 0rem;
  cursor: pointer;
}

.nova__navbar-links_text_view h1 {
  font-size: 1.6rem;
  line-height: 2.2rem;
  padding-bottom: 0px;
}
}