.nova-checkout-main-container {
  width: 100%;
  flex-direction: column;
  display: flex;
  /* padding: 0 16rem 16rem 7.7rem; */
  padding: 0 16rem 6rem 7.7rem;
}

.nova-checkout-main-heading p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 36px;
  color: #171d1c;
  margin: 3.8rem 0;
}

.nova-checkout-pink-main-container {
  width: 100%;
  background-color: #ffeff7;
  display: flex;
  flex-direction: column;
  padding: 2.5rem 6rem 3rem 3.5rem;
  border-radius: 1.6rem;
}

.nova-checkout-pink-heading p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 24px;
  color: #171d1c;
  margin-bottom: 2.5rem;
}

.nova-uncheck {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 8px;
  border: 1px solid #4f4f4f;
  cursor: pointer;
  margin-right: 3rem;
}

.nova-booking-confirm-drop-down-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nova-booking-add-custom-tip-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.5rem;
  background-color: rgb(255, 255, 255);
  width: 10rem;
  border-radius: 5px;
  border: 0.5px solid #ff4195;
  justify-content: space-between;
}

.nova-booking-add-custom-tip-container img {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.nova-booking-add-custom-tip-container input {
  width: 7rem !important;
  display: flex;
  background-color: rgb(255, 255, 255);
  outline: none;
  border: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #949494;
}

.nova-booking-add-custom-tip-container input::placeholder {
  color: #171d1c;
  font-size: 1.2rem;
}

@media screen and (max-width: 760px) {
  .nova-checkout-main-container {
    padding: 0 2rem 5rem 2rem;
  }
  .nova-checkout-pink-main-container {
    padding: 0 0 0 0;
  }
}

@media screen and (max-width: 360px) {
  .nova-checkout-pink-heading p {
    font-family: "Comfortaa";
    font-weight: 700;
    font-size: 24px;
    padding: 2rem;
    color: #171d1c;
    margin-bottom: 2.5rem;
}
}
