.nova-booking-start_comp_top_view {
  display: flex;
  flex-direction: column;
}

.nova-booking-start_comp_top_view h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 3.6rem;
  padding: 3.6rem 3.5rem 2.4rem 3.5rem;
  color: #171d1c;
}
  
.nova-booking-start_comp_top_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2rem;
  line-height: 3.6rem;
  padding: 3.6rem 3.5rem 2.4rem 3.5rem ;
  color: #171d1c;
}

.nova-booking-start_comp_item_view {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 0rem 1.6rem 0rem;
  /* padding: 1.2rem 3rem 1.2rem 1rem; */
  padding: 0rem 3rem 0rem 1rem;
  background: #ffffff;
  border-radius: 16px;
  cursor: pointer;
}

.nova-booking-start_comp_item_view h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #292929;
  width: 15rem;
}

.nova-booking-start_comp_item_view img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: cover;
}

.nova-booking-start_comp_next_button_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 2rem 2.1rem;
  cursor: pointer;
}

.nova-booking-start_comp_next_button_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #3d3d3d;
  padding-right: 0.5rem;
}

.nova-booking-start_comp_next_button_view img {
  width: 2.4rem;
  height: 2.4rem;
}

.accordion-main-container {
  /* background-color: red; */
  margin: 1rem 2.9rem 1rem 2.9rem;
  /* height: 4.8rem; */
  border-radius: 1.6rem !important;
}

.accordion-detail-container {
  /* margin: 0rem 2.9rem 1.6rem 2.9rem; */
  border-radius: 1.6rem !important;
  background-color: #ffffff !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px 3rem !important;
}
.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
  min-height: 0px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before {
  height: 0px !important;
}
.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
  box-shadow: none !important;
}

/* .accordion-main-container h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 18px;
  color: #292929;
} */

.accordion-main-container h1{
  padding: .4rem;
}

@media screen and (max-width: 1440px) {
  .nova-booking-start_comp_top_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .nova-booking-start_comp_item_view h2 {
    font-size: 1.4rem;
    line-height: 2rem;
  }

  .nova-booking-start_comp_item_view img {
    width: 2rem;
    height: 2rem;
  }

  .nova-booking-start_comp_next_button_view h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .nova-booking-start_comp_next_button_view img {
    width: 2rem;
    height: 2rem;
  }
}

@media screen and (max-width: 850px) {
  .nova-booking-start_comp_top_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-booking-start_comp_item_view h2 {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }

  .nova-booking-start_comp_item_view img {
    width: 1.8rem;
    height: 1.8rem;
  }

  .nova-booking-start_comp_next_button_view h3 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .nova-booking-start_comp_next_button_view img {
    width: 1.8rem;
    height: 1.8rem;
  }
}

@media screen and (max-width: 500px) {
  .nova-booking-start_comp_top_view h1 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-booking-start_comp_item_view img {
    width: 1.6rem;
    height: 1.6rem;
  }

  .nova-booking-start_comp_next_button_view h3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }

  .nova-booking-start_comp_next_button_view img {
    width: 1.6rem;
    height: 1.6rem;
  }
}

@media screen and (max-width: 425px) {
  .nova-booking-start_comp_top_view h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
  }

  .nova-booking-start_comp_item_view img {
    width: 1.4rem;
    height: 1.4rem;
  }

  .nova-booking-start_comp_next_button_view h3 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }

  .nova-booking-start_comp_next_button_view img {
    width: 1.4rem;
    height: 1.4rem;
  }
}

@media screen and (max-width: 360px) {
  .nova-booking-start_comp_top_view {
    display: flex;
    flex-direction: column;
    margin: -2rem;
  }

  .nova-booking-start_comp_item_view h2 {
    font-size: 1rem;
    line-height: 1.6rem;
    padding: 7px;
  }

  .nova-booking-start_comp_next_button_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin: 3rem 3.1rem;
    cursor: pointer;
  }

  .nova-booking-date_comp_single_slot_view {
    display: flex;
    flex-direction: column;
    padding-left: 4px;
  }

  .nova-booking-my_info_comp_radia_button_view div {
    width: 5rem !important;
    height: 2rem !important;
    border-radius: 1rem !important;
    background-color: #d7d7d7 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .nova-booking-my_info_comp_inputs_top_view {
    display: flex !important;
    flex-direction: column !important;
    padding-left: 1.8rem !important;
    padding-right: 2.2rem !important;
    margin-bottom: 5rem !important;
  }

  .nova-booking-date_comp_date_view {
    margin-bottom: 3rem !important;
    width: 116% !important;
  }

  .nova-booking-banner_image_view div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-left: 0.2rem;
    padding-bottom: 0.4rem;
    margin-bottom: 2rem;
    margin: 1rem;
}

.nova-booking-banner_image_view h2 {
  font-size: 1rem;
  line-height: 1.5rem;
  padding-left: 1rem;
}

/* .nova-booking-banner_image_view div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 0.2rem;
  padding-bottom: 0.4rem;
  margin-right: 5rem !important;
  margin-bottom: 2rem;
  margin: 1rem;
  } */

  .nova-booking-banner_image_view div {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    padding-left: 0.2rem !important;
    padding-bottom: 0.4rem !important;
    margin-right: 5rem !important;
    margin-bottom: 2rem;
    margin: 1rem;
}

.nova-booking-my_info_comp_radia_button_view h4 {
  font-size: 1.3rem;
  text-align: justify;
  display: flex;
  line-height: 2rem;
  }

  .nova-button_top_view {
    display: flex;
    position: relative;
    justify-content: center;
    margin-top: -3rem;
    /* outline: none; */
    margin-bottom: 2rem;
    border: none;
    background: none;
  }

  .nova-booking-date_comp_single_slot_view {
    display: flex;
    flex-direction: column;
    padding-left: 3.5px;
}

.nova-booking-date_comp_slots_view {
  padding: 0.5rem 0rem;
}

.nova-after-proceed-to-check-out-container {
  display: flex !important;
  padding: 5.1rem 3% !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  width: 70% !important;
  margin-bottom: 35px !important;
}

.nova-after-confirm-modal-container img {
  width: 10.4rem;
  height: 10.4rem;
  margin-top: -0.2rem !important;
}

.nova-button_main_view {
  /* position: absolute; */
  width: 15.2rem !important;
  height: 5.9rem;
  display: flex;
  margin-left: 2rem;
  margin-top: 4rem;
  align-items: center;
  justify-content: center;
  background-color: #ee509c;
  border-radius: 60% 60% 60% 60% / 60% 60% 60% 60%;
  cursor: pointer;
}

.nova-after-proceed-to-check-out {
  padding: 2rem;
  border-radius: 5px;
  border: 1px solid #ff4195ff;
}
}
