.nova-loader-main-container {
  width: 100%;
  height: 110vh;
  background: rgba(10, 10, 10, 0.31);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 100000;
}
