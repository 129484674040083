.nova-service_detail-banner_view {
  display: flex;
  flex-direction: column;
}

.nova-service_detail-banner_view div {
  width: 100%;
  height: 46.9rem;
  /* background-image: url(../../assets/waxingDark.png); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.nova-service_detail-banner_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 8rem;
  line-height: 8.9rem;
  color: #ffffff;
}

.nova-service_detail-detail_view {
  display: flex;
  flex-direction: column;
  margin-bottom: 8rem;
}

.nova-service_detail-detail_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  text-align: center;
  color: #222222;
  padding-left: 5rem;
  padding-right: 5rem;
}

.nova-service_detail-images_top_view {
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5rem 35.2rem 11.7rem 25.4rem;
}

.nova-service_detail-images_top_view h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #222222;
  width: 50%;
}

.nova-service_detail-images_top_view div {
  display: flex;
  width: 50%;
  justify-content: flex-end;
}

.nova-service_detail-images_top_view img {
  width: 47.1rem;
  height: 40rem;
  object-fit: cover;
  border-radius: 20px !important;
}

.nova-service_detail-images_top_view_two {
  text-align: justify;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0rem 28.6rem 7.2rem 27.8rem;
}

.nova-service_detail-images_top_view_two_image {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.nova-service_detail-images_top_view_two_image img {
  width: 45.1rem;
  height: 48rem;
  object-fit: cover;
  border-radius: 20px;
}

.nova-service_detail-images_top_view_two_text {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: flex-end;
}

.nova-service_detail-images_top_view_two_text h3 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  color: #222222;
  padding-bottom: 2.9rem;
}

.nova-service_detail-images_top_view_two_text h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #222222;
}

.nova-service_detail-items_top_view {
  display: flex;
  flex-direction: column;
  padding: 0rem 23.5rem 8rem 23.7rem;
}

.nova-service_detail-items_top_view div {
  padding: 1.1rem 4.2rem 1.3rem 4.8rem;
  background: #ee509c;
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nova-service_detail-items_top_view h5 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 2.4rem;
  color: #1a1818;
}

@media screen and (max-width: 1600px) {
  .nova-service_detail-banner_view div {
    height: 36.9rem;
  }

  .nova-service_detail-banner_view h1 {
    font-size: 6rem;
    line-height: 6.9rem;
  }

  .nova-service_detail-detail_view h1 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .nova-service_detail-images_top_view {
    padding: 5rem 15.2rem 8.7rem 15.4rem;
  }

  .nova-service_detail-images_top_view_two {
    padding: 0rem 18.6rem 5.2rem 17.8rem;
  }

  .nova-service_detail-items_top_view {
    padding: 0rem 13.5rem 5rem 13.7rem;
  }
}

@media screen and (max-width: 1270px) {
  .nova-service_detail-banner_view div {
    height: 32.9rem;
  }

  .nova-service_detail-banner_view h1 {
    font-size: 5rem;
    line-height: 5.9rem;
  }

  .nova-service_detail-detail_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .nova-service_detail-images_top_view h2 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-service_detail-images_top_view img {
    width: 37.1rem;
    height: 30rem;
  }

  .nova-service_detail-images_top_view_two_image img {
    width: 35.1rem;
    height: 38rem;
    object-fit: contain;
  }

  .nova-service_detail-images_top_view_two_text h3 {
    font-size: 3.2rem;
    line-height: 3.6rem;
  }

  .nova-service_detail-images_top_view_two_text h4 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-service_detail-items_top_view div {
    padding: 1rem 3.2rem 1rem 3.8rem;
    border-radius: 1rem;
  }

  .nova-service_detail-items_top_view h5 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 1050px) {
  .nova-service_detail-banner_view div {
    height: 26.9rem;
  }

  .nova-service_detail-banner_view h1 {
    font-size: 5rem;
    line-height: 5.9rem;
  }

  .nova-service_detail-detail_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    margin-top: 2rem;
  }

  .nova-service_detail-images_top_view {
    padding: 5rem 5.2rem 8.7rem 10.4rem;
  }

  .nova-service_detail-images_top_view_two {
    padding: 0rem 8.6rem 5.2rem 12.8rem;
  }

  .nova-service_detail-items_top_view {
    padding: 0rem 8.5rem 5rem 8.7rem;
  }
}

@media screen and (max-width: 920px) {
  .nova-service_detail-images_top_view {
    flex-direction: column;
  }

  .nova-service_detail-images_top_view h2 {
    padding-bottom: 5rem;
    width: 100%;
  }

  .nova-service_detail-images_top_view_two {
    flex-direction: column;
  }

  .nova-service_detail-images_top_view_two_text {
    width: 100%;
    margin-top: 3rem;
  }

  .nova-service_detail-images_top_view_two_image img {
    width: 30.1rem;
    height: 32rem;
  }

  .nova-service_detail-images_top_view div {
    justify-content: center;
  }

  .nova-service_detail-images_top_view_two_image {
    justify-content: center;
  }
}

@media screen and (max-width: 500px) {
  .nova-service_detail-banner_view div {
    height: 20rem;
  }

  .nova-service_detail-banner_view h1 {
    font-size: 4rem;
    line-height: 4.9rem;
  }

  .nova-service_detail-images_top_view {
    padding: 3rem 3.2rem 5.7rem 5.4rem;
  }

  .nova-service_detail-images_top_view_two {
    padding: 0rem 3.2rem 3.2rem 5.4rem;
  }

  .nova-service_detail-items_top_view {
    padding: 0rem 3.2rem 5rem 5.4rem;
  }
}

@media screen and (max-width: 425px) {
  .nova-service_detail-banner_view div {
    height: 15rem;
  }

  .nova-service_detail-banner_view h1 {
    font-size: 3rem;
    line-height: 3.9rem;
  }

  .nova-service_detail-detail_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-top: 1rem;
  }

  .nova-service_detail-images_top_view h2 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .nova-service_detail-images_top_view img {
    width: 25.1rem;
    height: 25rem;
  }

  .nova-service_detail-images_top_view_two_image img {
    width: 25.1rem;
    height: 25rem;
  }

  .nova-service_detail-images_top_view_two_text h3 {
    font-size: 2.6rem;
    line-height: 3rem;
    text-align: center;
  }

  .nova-service_detail-images_top_view_two_text h4 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }

  .nova-service_detail-items_top_view div {
    padding: 1rem 2.2rem 1rem 2.8rem;
    border-radius: 0.8rem;
  }

  .nova-service_detail-items_top_view h5 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}
