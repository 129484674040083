.nova-bucket-container {
  display: flex;
  flex-direction: column;
  padding: 5.6rem 4.5rem 0 4.5rem;
  width: 67rem;
}

.nova-bucket-total-cart {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.nova-bucket-total-cart h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 36px;
  color: #171d1c;
}

.nova-bucket-total-cart p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 24px;
  color: #292929;
}

.nova-checkout-btn-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.cart-product-information-heading h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  color: #292929;
  margin-top: 2rem;
}

.close-icon-drawer {
  display: none;
}

.close-icon-drawer img {
  width: 2.5rem;
  height: 2.5rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.empty-data-message {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-data-message h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 24px;
  color: #691717;
  margin-top: 2rem;
}

@media screen and (max-width: 740px) {
  .nova-bucket-container {
    width: 50rem;
  }
  .close-icon-drawer {
    display: flex;
  }
  .empty-data-message h2 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 560px) {
  .nova-bucket-container {
    width: 40rem;
    padding: 2.6rem 2.5rem 0 2.5rem;
  }
  .close-icon-drawer {
    display: flex;
  }
  .empty-data-message h2 {
    font-size: 1.6rem;
  }
}
