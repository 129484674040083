.nova-profile-container {
  display: flex;
  flex-direction: column;
  padding: 3.2rem 16.6rem;
}

.nova-profile-back_ground-image img {
  width: 100%;
  height: 31rem;
  object-fit: cover;
  border-radius: 114px 0px 0px 0px;
  cursor: pointer;
}

.nova-profile-back_ground-image {
  position: relative;
}
.nova-profile-update-profile-image {
  width: 4.1rem;
  height: 3.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255, 0.5);
  position: absolute;
  top: 22rem;
  right: 2rem;
  border-radius: 12px;
  cursor: pointer;
}

.nova-profile-back_ground-image .nova-profile-update-profile-image img {
  width: 2rem;
  height: 1.8rem;
  cursor: pointer;
}
.nova-profile-picture-main-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 25rem;
}

.nova-profile-picture-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.nova-profile-image-container img {
  width: 23rem;
  height: 23rem;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}
.nova-profile-profile_name-container {
  margin-left: 3.2rem;
}
.nova-profile-profile_name-container h1 {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 3.2rem;

  color: black;
}

.nova-profile-profile_name-container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: black;
}

.nova-profile-message-btn {
  width: 18.6rem;
  height: 4.8rem;
  background: #ee509c;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.nova-profile-message-btn p {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  color: #fff;
}

.nova-profile-message-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #fff;
  margin-right: 1rem;
}
.nova-profile-message-count p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ee509c;
}

.nova-profile-pink-main-container {
  width: 100%;
  background-color: #ffeff7;
  margin-top: 23.2rem;
  border-radius: 1.6rem;
}
.nova-profile-pink-top-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #ffc9e3;
  height: 6.4rem;
  border-radius: 1.6rem;
}
.nova-profile-pink-box-btn {
  width: 25%;
  height: 6.4rem;
  background: #ee509c;
  border-radius: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nova-profile-pink-box-btn p {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
}

/* -----------------------Media Query------------------- */
@media screen and (max-width: 1440px) {
  .nova-profile-container {
    padding: 3rem 17rem;
  }
  .nova-profile-profile_name-container h1 {
    font-size: 2.8rem;
  }
  .nova-profile-profile_name-container p {
    font-size: 1.8rem;
  }
  .nova-profile-message-btn p {
    font-size: 2rem;
  }
  .nova-profile-message-btn p {
    font-size: 1.8rem;
  }
  .nova-profile-pink-box-btn p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1200px) {
  .nova-profile-container {
    padding: 3.2rem 4rem;
  }
}

@media screen and (max-width: 900px) {
  .nova-profile-profile_name-container {
    margin: 0.5rem;
  }
  .nova-profile-profile_name-container h1 {
    font-size: 2.4rem;
  }
  .nova-profile-profile_name-container p {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .nova-profile-message-btn p {
    font-size: 1.6rem;
  }
  .nova-profile-pink-box-btn p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 750px) {
  .nova-profile-image-container img {
    width: 12rem;
    height: 12rem;
  }
  .nova-profile-back_ground-image img {
    height: 11rem;
  }
  .nova-profile-update-profile-image {
    top: 6rem;
    right: 1rem;
  }
  .nova-profile-picture-main-container {
    top: 8rem;
  }
  .nova-profile-profile_name-container h1 {
    font-size: 1.8rem;
  }
  .nova-profile-profile_name-container p {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
  .nova-profile-message-btn {
    width: 10rem;
    height: 3.8rem;
  }
  .nova-profile-message-btn p {
    font-size: 1.4rem;
  }
  .nova-profile-message-count {
    width: 1.5rem;
    height: 1.5rem;
  }
  .nova-profile-message-btn p {
    font-size: 1rem;
  }
  .nova-profile-container {
    padding: 3.2rem 2rem;
  }
  .nova-profile-pink-main-container {
    margin-top: 13rem;
  }
  .nova-profile-pink-box-btn p {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 550px) {
  .nova-profile-container {
    padding: 2rem 0.2rem;
  }
}

@media screen and (max-width: 420px) {
  .nova-profile-message-count {
    margin-right: 0.2rem;
  }
  .nova-profile-pink-box-btn p {
    line-height: 2rem;
    text-align: center;
  }
}
