.nova-signup_main_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-x: scroll; */
  padding-left: 1rem;
  padding-right: 1rem;
}

.signup-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 58%;
  justify-content: space-between;
}

.signup-div img{
  height: 40px;
  width: 40px;
  margin-top: 40px;
  cursor: pointer;
}

.nova-signup_main_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  color: #292929;
  padding-top: 6.8rem;
  padding-bottom: 2.8rem;
}

.nova-signup_profile_top_view {
  display: flex;
  position: relative;
  justify-content: center;
  padding-bottom: 1rem;
  cursor: pointer;
  margin-bottom: 4rem;
}

.nova-signup_profile_border_view {
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
  width: 9.6rem;
  height: 9.6rem;
  background-color: transparent;
  border: 1px solid #ee509c;
  border-radius: 5rem;
}

.nova-signup_profile_main_view {
  position: relative;
  width: 9.6rem;
  height: 9.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ee509c;
  border-radius: 5rem;
  cursor: pointer;
}

.nova-signup_profile_image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.nova-signup_profile_edit_icon {
  width: 1.4rem;
  height: 1.4rem;
  object-fit: cover;
}

.nova-signup_profile_edit_view {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  bottom: -5px;
  z-index: 10;
  right: 1rem;
  z-index: 10;
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 2rem;
  background-color: #ee509c;
}

.nova-signup_inputs_top_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 25.2rem;
  padding-right: 17.2rem;
  width: 100%;
  margin-bottom: 4rem;
  align-items: center;
  flex-wrap: wrap;
}

.nova-signup_input_view {
  width: 45.5rem;
  margin-right: 6.5rem;
}

@media screen and (max-width: 1465px) {
  .nova-signup_inputs_top_view {
    padding-left: 18.2rem;
    padding-right: 10.2rem;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 800px) {
  .nova-signup_inputs_top_view {
    padding-left: 12.2rem;
    align-self: center;
    padding-right: 5.2rem;
    margin-bottom: 4rem;
  }
}

@media screen and (max-width: 700px) {
  .nova-signup_inputs_top_view {
    padding-left: 2rem;
    padding-right: 2rem;
    margin-bottom: 4rem;
  }

  .nova-signup_input_view {
    width: 35.5rem;
    margin-right: 0rem;
  }
}

@media screen and (max-width: 470px) {
  .nova-signup_main_view h1 {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  .nova-signup_input_view {
    width: 27.5rem;
    margin-right: 0rem;
  }
}
