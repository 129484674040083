.nova-forgot_main_view {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* overflow-x: scroll; */
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .OTP-Verification-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 34%;
    justify-content: space-between;
  }
  
  .OTP-Verification-div img{
    height: 40px;
    width: 40px;
    margin-bottom: 20px !important;
    cursor: pointer;
  }