.nova-footer_top_view {
  background-color: #ee509c;
  padding: 6.5rem 17.5rem 3rem 16.6rem;
  margin-top: 5rem;
}

.nova-footer_detail_socials_top_view img{
  height: 40px !important;
  width: 40px !important;
}

.nova-footer_detail_socials_top_view {
  display: flex;
  align-items: left;
}

.nova-footer_detail_socials_top_view a {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.nova-footer_detail_socials_top_view img {
  width: 24px; 
  height: 24px; 
  margin-right: 8px; 
}

.nova-footer_detail_socials_top_view h1 {
  /* margin: 0px; */
  align-items: center !important;
  padding-bottom: 3.2rem;
}



.nova-footer_contact_us-top_view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 9.5rem;
}

.nova-footer_contact_us_view {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.nova-footer_contact_us_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.4rem;
  color: #ffffff;
  padding-bottom: 7.1rem;
}

.nova-footer_contact_us_view h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.4rem;
  color: #ffffff;
  cursor: pointer;
  padding-bottom: 3.6rem;
}

.nova-footer_contact_us_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #ffffff;
}

.nova-footer_contact_us_form_top_view {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-top: 3rem;
}

.nova-footer_contact_us_form_top_view input,
.nova-footer_contact_us_form_top_view textarea {
  border: none;
  outline: none;
  resize: none;
  background-color: #ee509c;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid #f284ba;
  font-family: "Poppins";
  margin-bottom: 4.5rem;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #ffffff;
}

::-webkit-input-placeholder {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 2.4rem;
  line-height: 2.9rem;
  color: #ffffff;
}

.nova-footer_contact_us_form_button_top_view {
  display: flex;
  position: relative;
  justify-content: center;
}

/* .nova-footer_contact_us_form_button_border_view {
  position: relative;
  width: 21.2rem;
  height: 8rem;
  border: 1px solid rgb(255, 255, 255);
  border-radius: 60% 60% 60% 60% / 60% 60% 60% 60%;
  transform: rotate(160deg);
} */

.nova-footer_contact_us_form_button_main_view {
  position: absolute;
  width: 15.2rem;
  height: 5.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 60% 60% 60% 60% / 60% 60% 60% 60%;
  cursor: pointer;
  border: none;
}

.nova-footer_contact_us_form_button_main_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 3rem;
  color: #292929;
}

.nova-footer_detail_top_view {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.nova-footer_detail_logo_top_view {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.nova-footer_detail_logo_top_view img {
  width: 11.2rem;
  height: 5rem;
  object-fit: contain;
  margin-bottom: 2.9rem;
}

.nova-footer_detail_logo_top_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #ffffff;
  margin-bottom: 2.7rem;
}

.nova-footer_detail_logo_top_view h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #ffffff;
}

.nova-footer_detail_links_top_view {
  display: flex;
  flex-direction: column;
  margin-left: 10rem;
}

.nova-footer_detail_contacts_top_view h1,
.nova-footer_detail_socials_top_view h1,
.nova-footer_detail_links_top_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.5rem;
  color: #ffffff;
  line-height: 105.47%;
  padding-bottom: 3.2rem;
}

.nova-footer_detail_contacts_top_view h2,
.nova-footer_detail_socials_top_view h2,
.nova-footer_detail_links_top_view h2 {
  font-family: "Comfortaa";
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 146.47%;
  color: #ffffff;
  cursor: pointer;
  padding-bottom: 0.8rem;
}

.nova-footer_detail_contacts_top_view span {
  font-family: "Comfortaa";
  font-weight: 500;
  font-size: 2.3rem;
  line-height: 146.47%;
  color: #ffffff;
}

.nova-footer_detail_socials_top_view {
  display: flex;
  flex-direction: column;
  margin-left: 8rem;
  margin-right: 12.1rem;
}

.nova-footer_detail_contacts_top_view {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.nova-footer_divider {
  display: flex;
  border: 1px solid rgb(255, 255, 255);
  margin: 4rem 15rem 2.1rem 15rem;
  align-self: center;
  align-items: center;
}

.nova-footer_bottom_view {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nova-footer_bottom_view p {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #ffffff;
  text-align: center;
}

@media screen and (max-width: 1440px) {
  .nova-footer_top_view {
    padding: 4.5rem 10.5rem 2rem 10.6rem;
  }

  .nova-footer_detail_socials_top_view img{
    height: 30px !important;
    width: 30px !important;
  }
  

  .nova-footer_contact_us_view h1 {
    font-size: 4rem;
    line-height: 4.6rem;
    padding-bottom: 5.1rem;
  }

  .nova-footer_contact_us_view h2 {
    font-size: 4rem;
    line-height: 4.6rem;
    padding-bottom: 1.6rem;
  }

  .nova-footer_contact_us_view h3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-footer_contact_us_form_top_view {
    width: 30%;
  }

  .nova-footer_contact_us_form_top_view input,
  .nova-footer_contact_us_form_top_view textarea {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  ::-webkit-input-placeholder {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .nova-footer_contact_us_form_button_border_view {
    width: 17.2rem;
    height: 5.9rem;
  }

  .nova-footer_contact_us_form_button_main_view {
    width: 17.2rem;
    height: 5.9rem;
  }

  .nova-footer_contact_us_form_button_main_view h3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-footer_detail_logo_top_view img {
    width: 10.2rem;
    height: 3.5rem;
  }

  .nova-footer_detail_logo_top_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .nova-footer_detail_logo_top_view h2 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #ffffff;
  }

  .nova-footer_detail_contacts_top_view h1,
  .nova-footer_detail_socials_top_view h1,
  .nova-footer_detail_links_top_view h1 {
    font-size: 2.2rem;
    line-height: 100.47%;
  }

  .nova-footer_detail_contacts_top_view h2,
  .nova-footer_detail_socials_top_view h2,
  .nova-footer_detail_links_top_view h2 {
    font-size: 1.6rem;
    line-height: 140.47%;
  }

  

  .nova-footer_detail_contacts_top_view span {
    font-size: 2rem;
    line-height: 140.47%;
  }

  .nova-footer_bottom_view p {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@media screen and (max-width: 1160px) {
  .nova-footer_detail_contacts_top_view {
    margin-top: 5rem;
  }
}

@media screen and (max-width: 1090px) {
  .nova-footer_contact_us_view {
    width: 100%;
  }

  .nova-footer_contact_us-top_view {
    flex-direction: column;
  }

  .nova-footer_contact_us_form_top_view {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
  }
}

@media screen and (max-width: 950px) {
  .nova-footer_detail_socials_top_view {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-top: 0rem !important;
  }

  .nova-footer_detail_links_top_view {
    margin-left: 10rem;
    margin-right: 8rem;
  }

  .nova-footer_top_view {
    padding: 4.5rem 5.5rem 2rem 5.6rem;
  }

  /* .nova-footer_detail_socials_top_view img{
    height: 25px !important;
    width: 25px !important;
  } */

  /* .nova-footer_detail_socials_top_view {
    margin-top: 0rem;
    padding-top: 50px;
    padding-right: 150px;
  } */

  /* .nova-footer_detail_contacts_top_view {
    margin-left: 0rem;
  } */

  /* .nova-footer_divider {
    margin: 4rem 5rem 2.1rem 5rem;
  } */
}

@media screen and (max-width: 781px) {
  .nova-footer_detail_socials_top_view {
    margin-top: 5rem;
  }

  .nova-footer_detail_socials_top_view img{
    height: 40px !important;
    width: 40px !important;
  }

  .nova-footer_detail_contacts_top_view {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-left: 12rem;
  }
}

@media screen and (max-width: 735px) {
  .nova-footer_top_view {
    padding: 4.5rem 5.5rem 2rem 5.6rem;
  }

  .nova-footer_detail_socials_top_view img{
    height: 25px !important;
    width: 25px !important;
  }

  .nova-footer_detail_socials_top_view {
    margin-top: 0rem;
    padding-top: 50px;
    padding-right: 150px;
  }

  .nova-footer_detail_contacts_top_view {
    margin-left: 0rem;
  }

  .nova-footer_divider {
    margin: 4rem 5rem 2.1rem 5rem;
  }
}

@media screen and (max-width: 680px) {
  .nova-footer_detail_socials_top_view {
    margin-top: 5rem;
  }

  .nova-footer_detail_contacts_top_view {
    margin-left: 5rem;
  }

  .nova-footer_detail_socials_top_view img{
    height: 20px !important;
    width: 20px !important;
  }

  .nova-footer_contact_us_view h1 {
    font-size: 3.5rem;
    line-height: 4rem;
    padding-bottom: 3.1rem;
  }

  .nova-footer_contact_us_view h2 {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .nova-footer_contact_us_view h3 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-footer_contact_us_form_top_view input,
  .nova-footer_contact_us_form_top_view textarea {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  ::-webkit-input-placeholder {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-footer_detail_logo_top_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-footer_detail_logo_top_view h2 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #ffffff;
  }

  .nova-footer_detail_contacts_top_view h1,
  .nova-footer_detail_socials_top_view h1,
  .nova-footer_detail_links_top_view h1 {
    font-size: 1.8rem;
    line-height: 95.47%;
  }

  .nova-footer_detail_contacts_top_view h2,
  .nova-footer_detail_socials_top_view h2,
  .nova-footer_detail_links_top_view h2 {
    font-size: 1.4rem;
    line-height: 135.47%;
  }

  .nova-footer_detail_contacts_top_view span {
    font-size: 1.6rem;
    line-height: 135.47%;
  }

  .nova-footer_bottom_view p {
    font-size: 1.2rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 525px) {
  .nova-footer_detail_logo_top_view {
    width: 100%;
  }

  .nova-footer_detail_socials_top_view{
    display: flex !important;
    flex-direction: column !important;
  }

  .nova-footer_detail_links_top_view {
    margin-left: 0rem;
    margin-top: 5rem;
  }

  .nova-footer_detail_contacts_top_view {
    margin-left: 0rem;
    flex: 0;
  }

  .nova-footer_detail_socials_top_view {
    display: flex;
    flex-direction: row;
    margin-right: 5.1rem;
  }
}

@media screen and (max-width: 400px) {
  .nova-footer_top_view {
    padding: 4.5rem 3.5rem 2rem 3.6rem;
  }

  .nova-footer_detail_socials_top_view{
    display: flex;
    flex-direction: column;
  }

  .nova-footer_contact_us_view h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .nova-footer_contact_us_view h2 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media screen and (max-width: 360px) {
  .nova-footer_detail_socials_top_view{
    display: flex;
    flex-direction: column;
  }

  .nova-footer_detail_top_view {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .nova-footer_contact_us_form_top_view input,
  .nova-footer_contact_us_form_top_view textarea {
    font-size: 1.6rem;
    line-height: 1rem;
  }

  .nova-footer_contact_us_view h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  .nova-footer_contact_us_form_top_view {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
  }

  .nova-footer_detail_contacts_top_view h1,
  .nova-footer_detail_socials_top_view h1,
  .nova-footer_detail_links_top_view h1 {
    font-size: 1.8rem;
    line-height: 0;
  }

  .nova-footer_top_view {
    padding: 4.5rem 3.5rem 2rem 3.6rem;
    margin-top: 4rem;
  }
}

