.nova-contact_page-main_view {
  display: flex;
  flex-direction: column;
}

.nova-contact_page-main_view h1 {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #171D1C;
  padding: 2.7rem 0rem 5rem 16.6rem;
}

.nova-contact_page_inputs_top_view {
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 56rem;
}

.nova-contact_page_inputs_button_view {
  display: flex;
  padding-top: 5rem;
  justify-content: center;
  padding-bottom: 7rem;
}

@media screen and (max-width:900px) {
  .nova-contact_page_inputs_top_view {
    width: 40rem;
  }
}

@media screen and (max-width:450px) {
  .nova-contact_page_inputs_top_view {
    width: 30rem;
  }

  .nova-contact_page-main_view h1 {
    text-align: center;
    padding: 2.7rem 0rem 5rem 0rem;
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
}

@media screen and (max-width:375px) {
  .nova-contact_page_inputs_top_view {
    width: 28rem;
  }

  .nova-contact_page-main_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}