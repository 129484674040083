.nova-reviews-banner_view {
  display: flex;
  flex-direction: column;
}

.nova-reviews-banner_view div {
  width: 100%;
  height: 59.1rem;
  background-image: url(../../assets/reviewBanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  padding-left: 16.6rem;
  margin-top: 1.1rem;
  padding-right: 5rem;
}

.nova-reviews-banner_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 8rem;
  line-height: 8.9rem;
  color: #ee509c;
}

.nova-reviews-reviews_list_top_view {
  display: flex;
  flex-direction: column;
  padding: 3.5rem 16.1rem 1.3rem 16.1rem;
}

.nova-reviews-single_review_item {
  display: flex;
  flex-direction: column;
  padding-bottom: 2.4rem;
}

.nova-reviews-single_review_item h1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #000000;
  padding-bottom: 0.3rem;
}

.nova-reviews-single_review_item div {
  display: flex;
  padding: 1.2rem 2rem 1.5rem 1.5rem;
  background-color: #efefef;
  border-radius: 1.6rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.nova-reviews-single_review_item h2 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #3d3d3d;
}

.nova-reviews-divider {
  display: flex;
  border-bottom: 1px solid #9e9e9e;
  margin-right: 15rem;
  margin-left: 15rem;
  margin-bottom: 2.4rem;
}

.nova-reviews-write_review_view {
  display: flex;
  flex-direction: column;
  padding: 0rem 16.1rem 5rem 16.1rem;
}

.nova-reviews-write_review_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #171d1c;
  padding-bottom: 2rem;
}

.nova-reviews-input_view {
  display: flex;
  padding: 1.2rem 2.2rem;
  width: 100%;
  background: #efefef;
  border-radius: 16px;
  /* margin-bottom: 5rem; */
}

.nova-reviews-input_view input {
  border: none;
  outline: none;
  font-family: "Poppins";
  background-color: transparent;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  color: #3d3d3d;
}

.nova-reviews-input_view input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  color: #3d3d3d;
}

.nova-reviews-textarea_view {
  display: flex;
  padding: 1.2rem 2.2rem;
  height: 10.4rem;
  width: 100%;
  background: #efefef;
  border-radius: 16px;
  /* margin-bottom: 5rem; */
}

.nova-reviews-textarea_view textarea {
  border: none;
  outline: none;
  resize: none;
  font-family: "Poppins";
  background-color: transparent;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  color: #3d3d3d;
}

.nova-reviews-textarea_view textarea::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 100%;
  color: #3d3d3d;
}

@media screen and (max-width: 1440px) {
  .nova-reviews-banner_view div {
    height: 39.1rem;
    padding-left: 12.6rem;
    padding-right: 2rem;
  }

  .nova-reviews-banner_view h1 {
    font-size: 5rem;
    line-height: 5.9rem;
  }

  .nova-reviews-reviews_list_top_view {
    padding: 3.5rem 12.1rem 1.3rem 12.1rem;
  }

  .nova-reviews-write_review_view {
    padding: 0rem 12.1rem 5rem 12.1rem;
  }

  .nova-reviews-divider {
    margin-right: 12rem;
    margin-left: 12rem;
  }
}

@media screen and (max-width: 900px) {
  .nova-reviews-banner_view div {
    height: 29.1rem;
    padding-left: 8.6rem;
    padding-right: 1rem;
  }

  .nova-reviews-banner_view h1 {
    font-size: 4rem;
    line-height: 4.9rem;
  }

  .nova-reviews-reviews_list_top_view {
    padding: 3.5rem 8.1rem 1.3rem 8.1rem;
  }

  .nova-reviews-write_review_view {
    padding: 0rem 8.1rem 5rem 8.1rem;
  }

  .nova-reviews-divider {
    margin-right: 10rem;
    margin-left: 10rem;
  }
}

@media screen and (max-width: 650px) {
  .nova-reviews-banner_view div {
    height: 19.1rem;
    padding-left: 5.6rem;
  }

  .nova-reviews-banner_view h1 {
    font-size: 3rem;
    line-height: 3.9rem;
  }

  .nova-reviews-reviews_list_top_view {
    padding: 3.5rem 5.1rem 1.3rem 5.1rem;
  }

  .nova-reviews-write_review_view {
    padding: 0rem 5.1rem 5rem 5.1rem;
  }

  .nova-reviews-divider {
    margin-right: 8rem;
    margin-left: 8rem;
  }

  .nova-reviews-write_review_view h1 {
    font-size: 2rem;
    line-height: 2.3rem;
  }
}

@media screen and (max-width: 500px) {
  .nova-reviews-banner_view div {
    height: 12.1rem;
    padding-left: 3.6rem;
  }

  .nova-reviews-banner_view h1 {
    font-size: 2rem;
    line-height: 2.9rem;
  }

  .nova-reviews-reviews_list_top_view {
    padding: 3.5rem 3.1rem 1.3rem 3.1rem;
  }

  .nova-reviews-single_review_item h1 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-reviews-single_review_item h2 {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-reviews-write_review_view {
    padding: 0rem 3.1rem 5rem 3.1rem;
  }

  .nova-reviews-divider {
    margin-right: 6rem;
    margin-left: 6rem;
  }

  .nova-reviews-write_review_view h1 {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }

  .nova-reviews-input_view input {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-reviews-input_view input::-webkit-input-placeholder {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-reviews-textarea_view textarea {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-reviews-textarea_view textarea::-webkit-input-placeholder {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 350px) {
  .nova-reviews-banner_view div {
    height: 10.1rem;
    padding-left: 2.6rem;
  }

  .nova-reviews-banner_view h1 {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}
