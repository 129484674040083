.nova-locations_main_view {
  display: flex;
  flex-direction: column;
}

.nova-locations_main_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  color: #171d1c;
  /* padding: 2.4rem 2.4rem 0rem 16.6rem; */
  text-align: center; /* Center-align the text */
}

.nova-locations-bottom_main_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 7rem;
  color: #171d1c;
  padding-top: -4rem !important;
  /* padding: 2.4rem 2.4rem 0rem 16.6rem; */
  text-align: center; /* Center-align the text */
}


.nova-locations_data_top_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0rem 16.8rem 4rem 20.4rem;
}

.nova-locations_single_location_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30.3rem;
  height: 44.3rem;
  background: #e6ecee;
  border-radius: 1.7rem;
  margin-right: 3.6rem;
  margin-bottom: 3.6rem;
  padding: 1.6rem 1.4rem;
}

.nova-locations_single_location_view img {
  width: 37.5rem;
  height: 22.6rem;
  object-fit: cover;
  border-radius: 1.7rem;
  margin-bottom: 1.4rem;
}

.nova-locations_single_location_view h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 3.7rem;
  padding-bottom: 0.7rem;
  padding-top: 1.7rem;
  text-align: center;
  color: black;
}

.nova-locations_single_location_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  text-align: center;
  color: #000000;
  padding-bottom: 2rem;
}

.nova-location_button_top_view {
  display: flex;
  position: relative;
  justify-content: center;
}

/* .nova-location_button_border_view {
  position: relative;
  width: 13.5rem;
  height: 4.6rem;
  border: 1px solid #ffffff;
  border-radius: 60% 60% 60% 60% / 60% 60% 60% 60%;
  transform: rotate(162deg);
} */

.nova-location_button_main_view {
  position: absolute;
  width: 13.5rem;
  height: 4.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 60% 60% 60% 60% / 60% 60% 60% 60%;
  cursor: pointer;
}

.nova-location_button_main_view h5 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ee509c;
}

@media screen and (max-width: 1727px) {
  .nova-locations_main_view h1 {
    font-size: 3.2rem;
    line-height: 3.6rem;
    padding: 2.8rem 2.4rem 3.6rem 12.6rem;
  }

  .nova-locations_data_top_view {
    padding: 0rem 11.8rem 0rem 15.4rem;
  }

  .nova-locations_single_location_view {
    width: 26.2rem;
    height: 39.3rem;
    border-radius: 1.2rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    padding: 1.4rem 1.2rem;
  }

  .nova-locations_single_location_view img {
    width: 24rem;
    height: 18.6rem;
    border-radius: 1.2rem;
    margin-bottom: 1.2rem;
  }

  .nova-locations_single_location_view h2 {
    font-size: 1.8rem;
    line-height: 2rem;
    padding-bottom: 0.4rem;
  }

  .nova-locations_single_location_view h3 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    padding-bottom: 3rem;
  }

  .nova-location_button_border_view {
    width: 13rem;
    height: 4rem;
    /* margin-top: 2rem; */
  }
  
  .nova-location_button_main_view{
    background-color: #ee509c;

  }

  .nova-location_button_main_view {
    width: 13rem;
    height: 4rem;
  }

  .nova-location_button_main_view h5 {
    font-size: 1.4rem;
    line-height: 2.2rem;
    color: #060707;
  }
}

@media screen and (max-width: 1162px) {
  .nova-locations_main_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
    padding: 2.4rem 2.4rem 3.6rem 8.6rem;
  }

  .nova-locations_data_top_view {
    padding: 0rem 7.8rem 4rem 11.4rem;
  }
}

@media screen and (max-width: 776px) {
  .nova-locations_main_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    padding: 2.4rem 2.4rem 3rem 5.6rem;
  }

  .nova-locations_data_top_view {
    padding: 0rem 4.8rem 4rem 7.4rem;
  }
}

@media screen and (max-width: 425px) {
  .nova-locations_main_view h1 {
    padding: 2.4rem 2.4rem 2.4rem 2.4rem;
  }

  .nova-locations_data_top_view {
    justify-content: center;
  }
}

@media screen and (max-width: 375px) {
  .nova-locations_main_view h1 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-locations_single_location_view {
    width: 23.2rem;
    height: 35.3rem;
    border-radius: 1rem;
    margin-right: 3rem;
    margin-right: 0rem;
    padding: 1.4rem 1.2rem;
  }

  .nova-locations_single_location_view img {
    width: 21rem;
    height: 16.6rem;
    border-radius: 1rem;
  }

  .nova-locations_single_location_view h2 {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .nova-locations_single_location_view h3 {
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding-bottom: 3rem;
  }
}

@media screen and (max-width: 360px) {


  .nova-locations-bottom_main_view h1 {
    font-family: "Comfortaa";
    font-weight: 700;
    font-size: 2.6rem;
    line-height: 0rem;
    color: #171d1c;
    padding-top: -4rem !important;
    /* padding: 2.4rem 2.4rem 0rem 16.6rem; */
    text-align: center; /* Center-align the text */
  }

  .nova-locations_main_view {
    display: flex;
    margin-right: 2.5rem;
    flex-direction: column;
  }

  .nova-locations_main_view h1 {
    font-size: 2rem;
    margin-left: 2rem;
    line-height: 2.4rem;
  }

  .nova-locations_data_top_view {
    justify-content: center;
    margin-bottom: -2rem;
  }
}

/* nova-locations_data_top_view */