.nova-my-profile-my_services-post_services-main-container {
  width: 100%;
  margin-top: 3.6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.nova-my-profile-my_services-post_services-card-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 3.2rem;
  flex-wrap: wrap;
}

/* ----------Media Query------------- */

@media screen and (max-width: 900px) {
  .nova-my-profile-my_services-post_services-card-container {
    flex-direction: column;
    align-items: center;
  }
}
