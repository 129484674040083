.nova-my-profile-my_rewards-main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.nova-my_profile-circle-progress-bar-container {
  align-self: center;
  width: 28rem;
  height: 28rem;
  margin-top: 5rem;
}

.nova-progress-bar-circular-text-container {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.nova-progress-bar-circular-text-container h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 7.3rem;
  color: black;
}

.nova-progress-bar-circular-text-container p {
  font-family: "Comfortaa";
  font-style: normal;
  font-weight: 700;
  font-size: 1.9rem;
  color: #000000;
}
.nova-my_profile-payment-card-details {
  width: 30rem;
  height: 11.7rem;
  border: 1px solid #ee509c;
  border-radius: 1.6rem;
  align-self: center;
  margin-top: 2rem;
}
.nova-my_profile-card-details-payment-main-container {
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.2rem;
}

.nova-my_profile-card-details-payment-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.nova-my_profile-card-details-payment-container h1 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 2rem;
  color: #000000;
}

.nova-my_profile-card-details-payment-container h2 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 2rem;
  color: #ee509c;
}

.nova-payment-card-previous-rewards-title h2 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 38px;
  color: #000000;
  margin-left: 4.7rem;
}

.nova-my-rewards-point-details-main-container {
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;
}
.nova-my-rewards-point-details-bottom-border {
  border-bottom: 1px solid #a5a5a5;
}
.nova-my-rewards-point-details-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.nova-my-rewards-point-details-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 29rem;
}

.nova-my-rewards-point-details-date-container img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.nova-my-rewards-point-details-date-container p {
  font-family: "poppins";
  font-weight: 400;
  font-size: 2.4rem;
  color: #3d3d3d;
  margin-left: 1.9rem;
}

.nova-my-rewards-point-details-payment-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nova-my-rewards-point-details-payment-container img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}

.nova-my-rewards-point-details-payment-container p {
  font-family: "poppins";
  font-weight: 700;
  font-size: 2.4rem;
  color: #3d3d3d;
  margin-left: 1.9rem;
}
/* ---------Media Query------------- */
@media screen and (max-width: 1440px) {
  .nova-progress-bar-circular-text-container h2 {
    font-size: 6.8rem;
  }
  .nova-progress-bar-circular-text-container p {
    font-size: 1.7rem;
  }
  .nova-my_profile-card-details-payment-container h1 {
    font-size: 1.8rem;
  }
  .nova-my_profile-card-details-payment-container h2 {
    font-size: 1.8rem;
  }
  .nova-payment-card-previous-rewards-title h2 {
    font-size: 2.8rem;
  }
  .nova-my-rewards-point-details-date-container p {
    font-size: 2.2rem;
  }
  .nova-my-rewards-point-details-payment-container p {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 850px) {
  .nova-payment-card-previous-rewards-title h2 {
    margin-top: 2rem;
  }
}

@media screen and (max-width: 620px) {
  .nova-payment-card-previous-rewards-title h2 {
    font-size: 2.4rem;
  }
  .nova-my-rewards-point-details-date-container p {
    font-size: 1.8rem;
    margin-left: 0.5rem;
  }
  .nova-my-rewards-point-details-payment-container p {
    font-size: 2rem;
  }
}

@media screen and (max-width: 520px) {
  .nova-my-rewards-point-details-main-container {
    padding: 1rem 0.5rem;
  }
  .nova-payment-card-previous-rewards-title h2 {
    margin-left: 0.5rem;
  }
  .nova-my-rewards-point-details-date-container p {
    font-size: 1.4rem;
  }
  .nova-my-rewards-point-details-payment-container p {
    font-size: 1.8rem;
  }
}
