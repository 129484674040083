.nova__navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0rem 5rem;
  height: 5.3rem;
  background: #ee509c;
}

.nova__navbar-links_text_view img{
  height: 35px;
  width: 35px;
  margin-right: 25px;
}

.nova__navbar-links-insta_text_view img{
  margin-left: 40px;
}

.nova__navbar-links {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nova_navbar-links_container {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.nova__navbar-links_text_view {
  padding-right: 4.8rem;
  display: flex;
  margin-top: 1.2rem;
}

.nova__navbar-links_book_now_button {
  display: flex;
  width: 10rem;
  height: 5.3rem;
  background: #292929;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0.2rem 3.8rem 0.2rem 0rem;
  cursor: pointer;
}

.nova__navbar-links_book_now_button h2 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
}

.nova__navbar-links_text_view h1 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #ffffff;
  cursor: pointer;
  border-bottom-width: 4px;
  padding-bottom: 1.2rem;
}

.nova_navbar-profile_view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 2rem;
  background-color: white;
  margin-left: 5rem;
  cursor: pointer;
}

.nova_navbar-profile_view img {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 2rem;
  object-fit: cover;
}

.nova__navbar_closeIcon {
  width: 2rem;
  height: 2rem;
}

.nova__navbar_menuIcon {
  width: 3rem;
  height: 3rem;
}

.nova__navbar-menu {
  margin-left: 1.6rem;
  display: none;
  position: relative;
  flex: 1;
}

.nova__navbar-menu img {
  cursor: pointer;
}

.nova__navbar-menu_container {
  display: flex;
  flex-direction: column;
  z-index: 100;
  text-align: start;
  background-color: #ee509c;
  padding: 3.2rem;
  position: absolute;
  top: 2.8rem;
  left: -6.5rem;
  margin-top: 2.4rem;
  min-width: 30rem;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
}

.nova__navbar-menu_container h5 {
  display: flex;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #ffffff;
  padding-bottom: 2rem;
  /* padding-right: 2.2rem; */
  /* margin-top: -10px; */
}


.nova_navbar-logo_view img {
  width: 9.1rem;
  height: 3.5rem;
  object-fit: contain;
}

.nova-navBar_button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13.2rem;
  height: 3.6rem;
  background: #ffc9e3;
  border-radius: 8px;
  margin-bottom: 2rem;
  cursor: pointer;
}

.nova-navBar_button h6 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: #ee509c;
}

.nova_navbar-Profile-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nova_navbar-cart-container {
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background-color: #ffffff;
  margin-left: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.nova_navbar-cart-container img {
  width: 2rem;
  height: 2rem;
  object-fit: contain;
}

.nova_navbar-cart-item {
  width: 1.7rem;
  height: 1.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: black;
  border-radius: 50%;
  bottom: 2.5rem;
  left: 2.5rem;
}
.nova_navbar-cart-item p {
  font-size: 1.3rem;
  color: #ffffff;
  font-family: "Poppins";
  font-weight: 400;
}

#gallery{
  display: none;
}

.dropbtn {
  /* background-color: #04AA6D; */
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content-border{
border-bottom: 1px solid black;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

/* .dropdown:hover .dropbtn {background-color: #3e8e41;} */

@media screen and (max-width: 1400px) {
  .nova__navbar-links_text_view {
    margin-right: -10px;
  }

  /* #gallery{
    display: block;
  } */

  /* .nova__navbar-links-contact_text_view h1{
    display: flex;
    flex-direction: row;
    width: 2vw;
  } */

  .nova__navbar-links_text_view h1 {
    font-size: 1.2rem;
    line-height: 2.2rem;
    width: max-content;
  }
}
@media screen and (max-width: 1260px) {
  .nova__navbar-links_book_now_button {
    margin-bottom: 1rem;
  }

  #gallery{
    display: block;
  }

  .nova__navbar-links_text_view h1 {
    flex: 1;
    border-bottom-width: 0;
  }

  .nova_navbar-profile_view {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    margin-left: 0rem;
  }

  .nova__navbar {
    display: flex;
    padding-top: 3rem;
    padding-bottom: 3rem;
    justify-content: flex-start;
    align-items: center;
  }

  .nova__navbar-links {
    display: none;
    justify-content: center;
    align-items: center;
  }

  .nova__navbar-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nova__navbar-links_text_view {
    padding-right: 0rem;
    padding-bottom: 1rem;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 550px) {
  .nova__navbar-menu_container {
    left: -4.5rem;
  }
  .nova__navbar {
    padding: 0rem 3rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .nova__navbar-links_logo img {
    width: 7rem;
    height: 7rem;
    object-fit: cover;
  }

  .nova__navbar-menu_container {
    top: 2.5rem;
    padding: 2.2rem;
    margin-top: 2.4rem;
    min-width: 20rem;
  }

  .nova__navbar-links_text_view h1 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 400px) {
  /* .nova_navbar-logo_view {
    display: none;
  } */
}
