.nova-product-main-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.justify-text {
  text-align: justify;
  /* display: inline; */
}

.nova-product-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.nova-product-main-img-container img {
  width: 50rem;
  height: 50rem;
  object-fit: contain;
}
.nova-product-details-main-container {
  display: flex;
  flex-direction: column;
  margin-left: 6.2rem;
  margin-bottom: 2rem;
}
.nova-product-name-main-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40rem;
}
.nova-product-nam-container p {
  font-family: "Comfortaa";
  font-style: normal;
  font-size: 24px;
  color: #051014;
  font-weight: 700;
}
.nova-product-off-container p {
  font-family: "poppins";
  font-style: normal;
  font-size: 24px;
  color: #051014;
  font-weight: 400;
}

.nova-product-description {
  width: 60rem;
}

.nova-product-description p {
  font-family: "poppins";
  font-size: 2rem;
  color: #292929;
  font-weight: 400;
  /* width: 50rem; */
}

.nova-product-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}

.nova-product-cross-price-container {
  position: relative;
}

.nova-product-cross-price-container img {
  width: 8.8rem;
  height: 2.7rem;
  position: absolute;
}

.nova-product-cross-price-container p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #051014;
}

.nova-product-price-product p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #051014;
  margin-left: 2rem;
}

.nova-product-qty-main-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.3rem;
  margin-left: 1rem;
}

.nova-product-qty-count-container {
  width: 5.1rem;
  height: 5.1rem;
  border-radius: 50%;
  background-color: #ee509c;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.nova-product-qty-count-container p {
  font-family: "poppins";
  font-weight: 400;
  font-size: 24px;
  line-height: 27px;
  color: #fff;
}

.nova-product-qty-container {
  width: 7.6rem;
  height: 5.1rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.nova-product-qty-container p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #000;
}

.nova-product-add_to_cart-btn {
  width: 20.1rem;
  height: 4.8rem;
  border-radius: 8px;
  background: #ee509c;
  margin-top: 3.2rem;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.nova-product-add_to_cart-btn {
  font-family: "poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  color: #fff;
}

@media screen and (max-width: 1250px) {
  .nova-product-container {
    margin-left: 0;
    margin-bottom: 5rem;
  }
  .nova-product-details-main-container {
    margin-left: 0;
  }
}

@media screen and (max-width: 640px) {
  .nova-product-description {
    width: 50rem;
  }
}

@media screen and (max-width: 520px) {
  .nova-product-main-img-container img {
    width: 40rem;
    height: 40rem;
  }
  .nova-product-name-main-container {
    width: 33rem;
  }

  .nova-product-description {
    width: 40rem;
  }
}
@media screen and (max-width: 480px) {
  .nova-product-nam-container p {
    font-size: 1.8rem;
  }
  .nova-product-off-container p {
    font-size: 1.8rem;
  }
  .nova-product-description p {
    font-size: 1.6rem;
  }
  .nova-product-description {
    width: 30rem;
  }
}

@media screen and (max-width: 440px) {
  .nova-product-main-img-container img {
    width: 34rem;
    height: 34rem;
  }
  /* .nova-services-main_view {
    padding: 0;
  } */
  .nova-product-details-main-container {
    margin-left: 0;
  }
}

@media screen and (max-width: 360px) {
  .nova-product-main-img-container img {
    width: 30rem;
    height: 21rem;
  }

  .nova-product-name-main-container {
    width: 30rem;
  }

  .nova-product-description p {
    font-size: 1.5rem;
    text-align: justify;
    display: flex;
  }

  .nova-product-qty-main-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: -3.7rem;
    margin-left: 14rem;
  }

  .nova-product-qty-count-container {
    width: 3.7rem;
    height: 3.7rem;
    border-radius: 50%;
    background-color: #ee509c;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
  }

  .nova-product-cross-price-container img {
    width: 5.8rem;
    height: 2.7rem;
    position: absolute;
  }
  
    .nova-product-qty-main-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: -3.7rem;
      margin-left: 15rem;
  }
}
