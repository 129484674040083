.nova-cart-product-image img {
  width: 9.2rem;
  height: 14.3rem;
  object-fit: contain;
}

.justify-text {
  text-align: justify;
  display: inline;
}

.nova-cart-product-main-container {
  display: flex;
  flex-direction: row;
}

.nova-cart-product-info-container {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
}

.nova-cart-product-info-container p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  color: #292929;
}

.nova-cart-product-info-container h3 {
  font-family: "Poppins";
  font-weight: 300;
  font-size: 16px;
  color: #292929;
  /* width: 50rem; */
}

.nova-product_in-cart-main-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.nova-product_in_cart-per-container {
  width: 8.4rem;
  height: 3.3rem;
  border-radius: 1.3rem;
  background: #9f9399;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nova-product_in_cart-per-container p {
  font-family: "Poppins";
  font-style: normal;
  font-size: 13.1959px;
  color: #ffffff;
}

.nova-cart-product-name-container {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 560px) {
  .nova-cart-product-info-container p {
    font-size: 1.6rem;
  }
  .nova-cart-product-info-container h3 {
    font-size: 1.4rem;
  }
  .nova-cart-product-info-container {
    margin-left: 0rem;
  }
}
