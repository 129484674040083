.signIn-container {
  width: 55rem;
  /* height: 50%; */
  background: #fff;
  border-radius: 3%;
  padding: 8rem 10rem;
}

.modal-loginText h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  color: #292929;
  padding-top: 4.1rem;
  text-align: center;
}
.login-continue h3 {
  cursor: pointer;
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.6rem;
  line-height: 4rem;
  color: #ee509c;
  /* padding-top: 4.1rem; */
  text-align: center;
  text-decoration: underline;
}
.Or-style {
  margin-top: 4rem;
  font-family: "Comfortaa";
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 4rem;
  color: #292929;
  margin-bottom: 4rem;
  text-align: center;
  text-decoration: underline;
}

@media screen and (max-width: 360px) {
  .signIn-container {
    width: 30rem;
    /* height: 50%; */
    background: #fff;
    height: 23rem;
    border-radius: 3%;
    padding: 8rem 10rem;
  }
  
  .login-continue h3 {
    cursor: pointer;
    font-family: "Comfortaa";
    font-weight: 700;
    /* margin-left: -6rem; */
    margin: -2rem -6rem;
    font-size: 2rem;
    /* display: inline-block; */
    line-height: 1rem;
    color: #ee509c;
    /* padding-top: 4.1rem; */
    text-align: center;
    text-decoration: underline;
  }
}
