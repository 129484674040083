.nova-forgot_main_view {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow-x: scroll; */
  padding-left: 1rem;
  padding-right: 1rem;
}

.Forgot-Password-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 34%;
  justify-content: space-between;
}

.Forgot-Password-div img{
  height: 40px;
  width: 40px;
  margin-top: 40px;
  margin-bottom: 50px !important;
  cursor: pointer;
  /* padding-left: 100px; */
}

.nova-forgot_main_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  color: #292929;
  padding-top: 8.1rem;
  padding-bottom: 9.4rem;
}

.nova-forgot_main_view h4 {
  font-family: "Comfortaa";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #292929;
  padding-bottom: 5.7rem;
}

.nova-forgot_inputs_view {
  display: flex;
  flex-direction: column;
  width: 56rem;
}

.nova-forgot_button_view {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5.7rem;
}

.nova-forgot_dont_have_account_text_view {
  display: flex;
  justify-content: center;
  margin-top: 5.9rem;
}

.nova-forgot_dont_have_account_text_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #292929;
  cursor: pointer;
  padding-top: 70px;
}

@media screen and (max-width: 700px) {
  .nova-forgot_inputs_view {
    width: 40rem;
  }

  .nova-forgot_main_view h5 {
    text-align: center;
    padding-left: 5rem;
    padding-right: 5rem;
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}

@media screen and (max-width: 470px) {
  .nova-forgot_inputs_view {
    width: 27rem;
  }

  .nova-forgot_main_view h1 {
    font-size: 3rem;
    line-height: 3.6rem;
  }

  .nova-forgot_forgot_password_text_view h2 {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }

  .nova-forgot_dont_have_account_text_view h3 {
    font-size: 1.4rem;
    line-height: 1.7rem;
  }
}

@media screen and (max-width: 360px) {
  .Forgot-Password-div img {
    height: 26px;
    width: 29px;
    margin-right: 3rem;
    margin-left: -8rem;
    margin-top: 35px;
    margin-bottom: 50px !important;
    cursor: pointer;
    /* padding-left: 100px; */
}

.nova-forgot_main_view h4 {
  font-family: "Comfortaa";
  font-weight: 400;
  margin-top: -4rem;
  font-size: 1.3rem;
  padding-right: 5.7rem;
  /* margin-right: 3rem; */
  margin-left: 7rem;
  width: 100%;
  line-height: 1.9rem;
  color: #292929;
  padding-bottom: 2.7rem;
} 

.nova-input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: -0.6rem;
}

.nova-forgot_dont_have_account_text_view h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #292929;
  cursor: pointer;
  padding-top: 40px;
}

.nova-forgot_main_view h1 {
  font-family: "Comfortaa";
  font-weight: 1000;
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  color: #292929;
  padding-top: 8.1rem;
  padding-bottom: 9.4rem;
}

.nova-login_forgot_password_text_view h2 {
  font-size: 1.2rem;
  line-height: 7.9rem;
}
}