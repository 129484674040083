.nova-booking-banner_view {
  display: flex;
  flex-direction: column;
  padding: 6.4rem 16.6rem 3.4rem 16.6rem;
}

.nova-booking-banner_view h1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nova-booking-banner_view h1 span {
  background-color: #f04f9c;
  padding: 5px 0px 5px 5px;
  display: inline-block;
  border-radius: 10px;
  margin-bottom: 15px;
  color: white;
}


.nova-booking-banner_image_view {
  width: 100%;
  height: 51.2rem;
  background-image: url(../../assets/bookingBanner.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.nova-booking-banner_image_view div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-left: 1.2rem;
  padding-bottom: .4rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
}

.inner-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  opacity: 0.4;
  padding-right: 10px;
  padding-top: 8px;
  border-radius: 16px;
}

.nova-booking-banner_image_view img {
  max-width: 100%;
}

.nova-booking-banner_image_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.8rem;
  line-height: 5.4rem;
  color: #000000;
  padding: 0 5rem 2.1rem 5rem;
}

.nova-booking-banner_image_view h2 {
  font-family: "Comfortaa";
  font-weight: 400;
  font-size: 2.2rem;
  line-height: 3.8rem;
  /* padding-left: 2.2rem; */
  color: #ffffff;
}

.nova-booking-banner_image_view img {
  width: 2.6rem;
  height: 2.6rem;
  object-fit: cover;
}

.nova-booking-detail_top_view {
  display: flex;
  flex-direction: column;
  margin: 0rem 16.6rem 6.9rem 16.6rem;
  background-color: #ffeff7;
  border-radius: 1.6rem;
}

.nova-booking-detail_header_top_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffc9e3;
  border-radius: 1.6rem;
  height: 6.4rem;
}

.nova-booking-detail_header_view {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ee509c;
}

.nova-booking-detail_header_view h3 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 2.7rem;
  color: #ffffff;
  display: flex;
}

.nova-booking-detail_header_view h4 {
  display: none;
  font-family: "Comfortaa";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #ffffff;
}

@media screen and (max-width: 1440px) {
  .nova-booking-banner_view {
    padding: 6.4rem 10.6rem 3.4rem 10.6rem;
  }

  .nova-booking-detail_top_view {
    margin: 0rem 10.6rem 6.9rem 10.6rem;
  }

  .nova-booking-banner_image_view h1 {
    font-size: 3.8rem;
    line-height: 4.4rem;
  }

  .nova-booking-banner_image_view h2 {
    font-size: 1.8rem;
    line-height: 3.4rem;
  }

  .nova-booking-banner_image_view {
    height: 41.2rem;
  }

  .nova-booking-detail_header_view h3 {
    font-size: 2rem;
    line-height: 2.3rem;
  }
}

@media screen and (max-width: 850px) {
  .nova-booking-detail_header_top_view {
    height: 5.4rem;
  }

  .nova-booking-banner_view {
    padding: 4.4rem 5.6rem 3.4rem 5.6rem;
  }

  .nova-booking-detail_top_view {
    margin: 0rem 5.6rem 6.9rem 5.6rem;
  }

  .nova-booking-banner_image_view h1 {
    font-size: 3.4rem;
    line-height: 4rem;
  }

  .nova-booking-banner_image_view h2 {
    font-size: 2.4rem;
    line-height: 3rem;
  }

  .nova-booking-banner_image_view {
    height: 35.2rem;
  }

  .nova-booking-detail_header_view h3 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .nova-booking-detail_header_view h3 {
    display: none;
  }

  .nova-booking-detail_header_view h4 {
    display: flex;
  }

  .nova-booking-detail_header_top_view {
    height: 3.5rem;
  }

  .nova-booking-banner_image_view {
    height: 25.2rem;
  }

  .nova-booking-banner_view {
    padding: 2rem 3rem 3rem 3rem;
  }

  .nova-booking-detail_top_view {
    margin: 0rem 3rem 4.9rem 3rem;
  }
}

@media screen and (max-width: 360px) {
  .nova-booking-banner_image_view {
    height: 15.2rem;
  }

  .nova-booking-banner_image_view h1 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-booking-banner_image_view h2 {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .nova-booking-banner_image_view img {
    width: 2rem;
    height: 2rem;
    object-fit: cover;
  }
}
