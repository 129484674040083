.nova-top_bar-main_view {
  display: flex;
  flex-direction: row;
  flex-direction: column;
}

.nova-top_bar-data_view {
  /* padding: 1.6rem 2rem 1.4rem 2rem; */
  padding: 1.6rem 2rem 0.2rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.nova-top-bar-logo-view img {
  width: 17.7rem;
  height: 7.15rem;
  object-fit: cover;
  margin-right: 7.4rem;
}

.nova-top_bar-data_view h2 {
  display: flex;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #292929;
  padding-right: 2.2rem;
}

.nova-top_bar-small_divider {
  display: flex;
  border: 1px solid #a5a5a5;
  height: 3.8rem;
  margin-right: 3.7rem;
}

.nova-top_bar-buttons_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 9rem;
}

.nova-top_bar-login_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 4rem;
  height: 3.6rem;
  background: #ffc9e3;
  border-radius: 8px;
  margin-right: 1.6rem;
  cursor: pointer;
}

.nova-top_bar-login_button h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #ee509c;
}

.nova-top_bar-signup_button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 2.8rem;
  height: 3.6rem;
  /* width: 100%; */
  background: #ee509c;
  border-radius: 8px;
  cursor: pointer;
}
.nova-top_bar-gallery_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* padding: 0rem 1.8rem; */
  margin-left: 20px !important;
  height: 3.6rem;
  /* border-bottom: 3px solid #ee509c; */
  /* margin-bottom: 10px; */
  /* background: #ee509c; */
  /* border-radius: 8px; */
  cursor: pointer;
}

.nova-top_bar-gallery_button > img{
  object-fit: contain !important;
  height: 100%;
  margin-right: 10px !important;
}

.nova-top_bar-signup_button h3 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #ffffff;
}

.nova-top_bar-divider {
  display: flex;
  border: 1px solid #a5a5a5;
  margin-bottom: 1.8rem;
}

.clock-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.clock-view img {
  width: 4.8rem;
  height: 4.8rem;
  object-fit: contain;
}
.clock-view-container {
  flex-direction: column;
  margin-left: 1rem;
}
.clock-view-container p {
  font-family: "Poppins";
  font-size: 1.4rem;
  color: #a5a5a5;
  font-weight: 400;
}
.clock-view-container h1 {
  font-family: "Poppins";
  font-size: 2rem;
  color: #292929;
  font-weight: 500;
}

@media screen and (max-width: 1355px) {
  .nova-top_bar-signup_button h3 {
    /* padding: 0rem 1rem; */
    font-size: 12px;
    width: 5vw;
  }
}
@media screen and (max-width: 1440px) {
  .nova-top_bar-data_view {
    padding: 1.2rem 2rem 1rem 2rem;
  }

  nova-top_bar-gallery_button

  .nova-top_bar-data_view img {
    width: 7.1rem;
    height: 2.9rem;
    object-fit: cover;
    margin-right: 5.4rem;
  }

  .nova-top_bar-data_view h2 {
    font-size: 1.6rem;
    line-height: 2.6rem;
    color: #292929;
    padding-right: 2rem;
  }

  .nova-top_bar-buttons_view {
    margin-left: 5rem;
  }

  .nova-top_bar-login_button {
    padding: 0rem 1rem;
    height: 3.5rem;
  }

  .nova-top_bar-login_button h3 {
    font-size: 1.6rem;
    line-height: 1.6rem;
  }

  .nova-top_bar-signup_button {
    padding: 0rem 3rem;
    /* height: 3.5rem; */
    display: flex;
    flex-direction: row;
  }

  .nova-top_bar-signup_button h3 {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    line-height: 2.6rem;
    color: #ffffff;
  }

  .nova-top_bar-divider {
    margin-bottom: 1.2rem;
  }
}

@media screen and (max-width: 1161px) {
  .nova-top_bar-main_view {
    display: none;
  }
}
