.nova-text_input-profile {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.nova-text_input-profile p {
  width: 16rem;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 2rem;
  line-height: 30px;
  color: #3d3d3d;
}
.nova-text_input-container {
  display: flex;
  flex-direction: column;
}

.nova-text_input-container input {
  width: 68.8rem;
  height: 5.4rem;
  border: 1px solid #ee509c;
  border-radius: 8px;
  padding: 1.2rem 2.2rem;
  background-color: #ffeff7;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: #3d3d3d;
  outline: none;
}

.nova-text_input-container input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.nova-text_input-container
  input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: none no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  content: url(../../assets/calender-icon.png);
}

/* ------------------Media Query-------------------- */
@media screen and (max-width: 1440px) {
  .nova-text_input-container input {
    width: 50rem;
  }
}

@media screen and (max-width: 1200px) {
  .nova-text_input-profile {
    width: 94%;
  }
}

@media screen and (max-width: 900px) {
  .nova-text_input-container input {
    width: 40rem;
  }
  .nova-text_input-profile p {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 750px) {
  .nova-text_input-profile {
    flex-direction: column;
    /* align-self: flex-start; */
    align-items: normal;
  }
}

@media screen and (max-width: 750px) {
  .nova-text_input-container input {
    width: 30rem;
  }
  .nova-text_input-container input {
    font-size: 1.4rem;
  }
}
