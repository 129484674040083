.dropdown {
  width: 10rem;
  user-select: none;
  /* display: flex;
    flex-direction: row; */
  position: relative;
}
.dropdown p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #292929;
  margin-bottom: 0.3rem;
}
.dropdown .dropdown-btn {
  width: 10rem;
  height: 6rem;

  /* border: 1px solid #667080; */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 15.0803px;
  color: rgb(255, 255, 255);
  display: flex;
  /* align-items: center; */
  cursor: pointer;
  justify-content: space-between;
  /* background-color: red; */
  align-items: center;
}

.dropdown .dropdown-content {
  position: absolute;
  height: 20rem;
  width: 10rem;
  top: 110%;
  left: 0 rem;
  padding: 10px;
  font-weight: 400;
  font-size: 15.0803px;
  color: #fff;
  border: 1px solid #667080;
  box-shadow: 3px 3px 10px 6px rgba(0, 0, 0, 0.06);
  font-weight: 500;
  /* color: #333; */
  /* width: 100%; */
  background-color: #ffeff7;
  z-index: 10;
  overflow-y: scroll;
}

.dropdown .dropdown-content::-webkit-scrollbar {
  width: 1px;
}
.dropdown .dropdown-content::-webkit-scrollbar-track {
  /* background: #666666; */
  border-radius: 2px;
}
.dropdown .dropdown-content::-webkit-scrollbar-thumb {
  background: rgba(255, 0, 0, 0);
  border-radius: 10px;
  /* width: 10px; */
}
.dropdown .dropdown-content::-webkit-scrollbar-thumb:hover {
  background: #666666;
  width: 10px;
}
.dropdown .dropdown-content .dropdown-item {
  padding: 10px;
  cursor: pointer;
  transition: all 0.2s;
}
.dropdown .dropdown-content .dropdown-item:hover {
  color: gray;
}

.dropdown .dropdown-btn img {
  width: 20px;
  height: 20px;
}

/* --------Media Query--------- */

/* @media screen and (max-width: 720px) {
  .dropdown p {
    font-size: 1.2rem;
  }
  .dropdown .dropdown-btn img {
    width: 1rem;
    height: 1rem;
  }
  .dropdown .dropdown-btn {
    width: 7rem !important;
  }
  .dropdown {
    width: 7rem !important;
  }
  .dropdown .dropdown-content {
    width: 13rem !important;
  }
}

@media screen and (max-width: 520px) {
  .dropdown .dropdown-btn {
    width: 6rem !important;
  }
  .dropdown {
    width: 6em !important;
  }
  .dropdown .dropdown-content {
    width: 8rem !important;
  }
} */
