.nova-about-us_main_view {
  display: flex;
  flex-direction: column;
  padding-top: 4.4rem;
  padding-right: 30rem;
  padding-left: 30rem;
}

.nova-about-us_main_view h1 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 4.5rem;
  line-height: 5.4rem;
  text-align: center;
  color: #171d1c;
  padding-bottom: 4.6rem;
}

.nova-about-us_main_view h2 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.6rem;
  line-height: 4rem;
  color: #292929;
  text-align: center;
  padding-bottom: 2.6rem;
}

.nova-about-us_percantage_view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-left: 7rem;
}

.nova-about-us_percantage_view div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2.5rem;
  margin-right: 2.5rem;
}

.nova-about-us_percantage_view h3 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 7.1rem;
  line-height: 8.5rem;
  color: #292929;
  padding-bottom: 0.5rem;
}

.nova-about-us_percantage_view h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #292929;
  width: 11.8rem;
  text-align: center;
}

.nova-about-us_detail_view {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 32.7rem 0rem 35.4rem;
}

.nova-about-us_single_item_top_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 1.6rem;
}

.nova-about-us_single_item_top_view img {
  width: 32.3rem;
  height: 36.1rem;
  object-fit: contain;
  border-radius: 20px;
}

.nova-about-us_single_item_top_view div {
  display: flex;
  width: 45%;
  justify-content: center;
  flex-direction: column;
}

.nova-about-us_single_item_top_view h3 {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #171d1c;
  padding-bottom: 0.2rem;
}

.nova-about-us_single_item_top_view h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
  color: #292929;
}

@media screen and (max-width: 1700px) {
  .nova-about-us_main_view {
    padding-right: 20rem;
    padding-left: 20rem;
  }

  .nova-about-us_detail_view {
    padding: 2.5rem 22.7rem 0rem 25.4rem;
  }
}

@media screen and (max-width: 1488px) {
  .nova-about-us_main_view {
    padding-right: 15rem;
    padding-left: 15rem;
  }

  .nova-about-us_detail_view {
    padding: 2.5rem 17.7rem 0rem 20.4rem;
  }

  .nova-about-us_main_view h1 {
    font-size: 4rem;
    line-height: 4.6rem;
  }

  .nova-about-us_main_view h2 {
    font-size: 3rem;
    line-height: 3.4rem;
  }

  .nova-about-us_percantage_view {
    margin-right: 5rem;
    margin-left: 10rem;
  }

  .nova-about-us_percantage_view h3 {
    font-size: 5.1rem;
    line-height: 6.5rem;
  }

  .nova-about-us_percantage_view h4 {
    font-size: 2rem;
    line-height: 2.5rem;
    width: 9.8rem;
  }

  .nova-about-us_single_item_top_view img {
    width: 28.3rem;
    height: 32.1rem;
  }

  .nova-about-us_single_item_top_view h3 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .nova-about-us_single_item_top_view h4 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media screen and (max-width: 1000px) {
  .nova-about-us_main_view {
    padding-right: 10rem;
    padding-left: 10rem;
  }

  .nova-about-us_detail_view {
    padding: 2.5rem 12.7rem 0rem 15.4rem;
  }
}

@media screen and (max-width: 800px) {
  .nova-about-us_single_item_top_view div {
    width: 100%;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .nova-about-us_single_item_top_view {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .nova-about-us_main_view {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  .nova-about-us_detail_view {
    padding: 2.5rem 12.7rem 0rem 15.4rem;
  }

  .nova-about-us_main_view h1 {
    font-size: 3.5rem;
    line-height: 4rem;
  }

  .nova-about-us_main_view h2 {
    font-size: 2.6rem;
    line-height: 3rem;
  }

  .nova-about-us_percantage_view h3 {
    font-size: 4.1rem;
    line-height: 5.5rem;
  }

  .nova-about-us_percantage_view h4 {
    font-size: 1.6rem;
    line-height: 2rem;
    width: 8.8rem;
  }

  .nova-about-us_single_item_top_view img {
    width: 25.3rem;
    height: 27.1rem;
  }

  .nova-about-us_single_item_top_view h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-about-us_single_item_top_view h4 {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
}

@media screen and (max-width: 475px) {
  .nova-about-us_main_view {
    padding-right: 3rem;
    padding-left: 3rem;
  }

  .nova-about-us_detail_view {
    padding: 2.5rem 5.7rem 0rem 5.7rem;
  }

  .nova-about-us_main_view h1 {
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .nova-about-us_main_view h2 {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }

  .nova-about-us_percantage_view h3 {
    font-size: 3.1rem;
    line-height: 4.5rem;
  }

  .nova-about-us_percantage_view h4 {
    font-size: 1.4rem;
    line-height: 1.8rem;
    width: 7.8rem;
  }

  .nova-about-us_single_item_top_view img {
    width: 22.3rem;
    height: 24.1rem;
  }

  .nova-about-us_single_item_top_view h3 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-about-us_single_item_top_view h4 {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

@media screen and (max-width: 415px) {
  .nova-about-us_percantage_view {
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 375px) {
  .nova-about-us_single_item_top_view img {
    width: 15.3rem;
    height: 18.1rem;
  }
}

#small-text{
  display: none;
}

@media screen and (max-width: 360px) {
  #big-text{
    display: none;
  }

  #small-text{
    display: block
  }
} 
