.nova-select-service-container {
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 20px;
  overflow: scroll;
}

.nova-select-service-container {
  margin: 4rem 10rem;
  background-color: #ffeff7;
  padding: 4rem 6rem 2rem 6rem;
}

.service-in-modal-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 4rem;
}

.service-amount-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.service-amount-sub-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.service-amount-sub-container h3,
p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 26px;
  /* color: #171d1c; */
}

@media screen and (max-width: 560px) {
  .nova-select-service-container {
    width: 96%;
  }
  .nova-select-service-container {
    margin: 4rem 2rem;
  }
  .nova-select-service-container {
    margin: 0;
    padding: 2rem 2rem 2rem 2rem;
  }
}
