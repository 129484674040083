.nova-input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
}

.nova-input_container p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 2rem;
  line-height: 3rem;
  color: #ee509c;
}

.nova-input_container_input {
  margin-top: 0.6rem;
  width: 100%;
  height: 4.4rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 2px solid #a5a5a5;
  padding: 0rem 1rem 0rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nova-input-error-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nova-input-error-container img {
  width: 1.5rem;
  height: 1.5rem;
}
.nova-input-error-container h5 {
  font-size: 1.2rem;
  color: #ff0000;
  margin-left: 0.5rem;
}

.nova-input_container_input input {
  width: 100% !important;
  display: flex;
  background-color: rgb(255, 255, 255);
  outline: none;
  border: none;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #949494;
}

.nova-input_container_input img {
  width: 2.5rem;
  height: 2.5rem;
  margin-left: 1rem;
  object-fit: contain;
}

.nova-input_container_input
  input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 1;
  background: none no-repeat;
  width: 2.5rem;
  height: 2.5rem;
  content: url(../../assets/calender.png);
}

.nova-input_container_input input::-webkit-input-placeholder {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #949494;
}

@media screen and (max-width: 470px) {
  .nova-input_container p {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }

  .nova-input_container_input input {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  .nova-input_container_input img {
    width: 2.2rem;
    height: 2.2rem;
  }

  .nova-input_container_input input::-webkit-input-placeholder {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width: 470px) {
  .nova-input_container p {
    font-size: 1.6rem;
    line-height: 2.6rem;
    margin-top: 1rem;
  }
}
