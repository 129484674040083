.nova-booking-service_comp_top_view {
  display: flex;
  flex-direction: column;
}

.nova-booking-service_comp_top_view h1 {
  font-family: 'Comfortaa';
  font-weight: 700;
  font-size: 3.2rem;
  line-height: 3.6rem;
  padding: 3.6rem 3.5rem 2.4rem 3.5rem;
  color: #171D1C;
}

.nova-booking-service_comp_item_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 5.6rem 1.2rem 2.8rem;
  margin: 0rem 2.9rem 1.6rem 2.9rem;
  border-radius: 1.6rem;
  cursor: pointer;
}

.nova-booking-service_comp_item_view h2 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  width: 10%;
  color: #292929;
}

.nova-booking-service_comp_item_view h3 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #292929;
}

.nova-booking-service_comp_button_top_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1.4rem 2.9rem 1.6rem 2.2rem;

}

.nova-booking-service_comp_previous_button_view {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.nova-booking-service_comp_previous_button_view img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: cover;
}

.nova-booking-service_comp_previous_button_view h3 {
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.9rem;
}

@media screen and (max-width:1440px) {
  .nova-booking-service_comp_top_view h1 {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }

  .nova-booking-service_comp_item_view h2 {
    font-size: 1.4rem;
    line-height: 2rem;
    width: 20%;

  }

  .nova-booking-service_comp_item_view h3 {
    font-size: 1.4rem;
    line-height: 2rem;
    color: #292929;
  }

  .nova-booking-service_comp_previous_button_view img {
    width: 2rem;
    height: 2rem;
  }

  .nova-booking-service_comp_previous_button_view h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

@media screen and (max-width:850px) {
  .nova-booking-service_comp_top_view h1 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }

  .nova-booking-service_comp_item_view h2 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    /* width: 20%; */
  }

  .nova-booking-service_comp_item_view h3 {
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  .nova-booking-service_comp_previous_button_view img {
    width: 1.8rem;
    height: 1.8rem;
  }

  .nova-booking-service_comp_previous_button_view h3 {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

@media screen and (max-width:635px) {
  .nova-booking-service_comp_item_view h2 {
    width: 40%;
  }
}

@media screen and (max-width:500px) {
  .nova-booking-service_comp_top_view h1 {
    font-size: 2rem;
    line-height: 2.4rem;
  }

  .nova-booking-service_comp_previous_button_view img {
    width: 1.6rem;
    height: 1.6rem;
  }

  .nova-booking-service_comp_previous_button_view h3 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
}

@media screen and (max-width:425px) {
  .nova-booking-service_comp_item_view {
    padding: 1rem 2rem 1rem 2rem;
    margin: 0rem 2rem 1rem 2rem;
    border-radius: 1.4rem;
    cursor: pointer;
  }

  .nova-booking-service_comp_top_view h1 {
    font-size: 1.6rem;
    line-height: 2rem;
    text-align: center;
  }

  .nova-booking-service_comp_previous_button_view img {
    width: 1.4rem;
    height: 1.4rem;
  }

  .nova-booking-service_comp_previous_button_view h3 {
    font-size: 1.2rem;
    line-height: 1.6rem;
  }
}