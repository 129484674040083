.nova-my-profile-service-card-main_container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.nov-my-profile-service-card-img-container img {
  width: 30rem;
  height: 24rem;
  object-fit: cover;
}

.nova-my_profile-service-card-price-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 30rem;
  margin-top: 1.6rem;
}
.nova-my_profile-service-card-price-container p {
  font-family: "Comfortaa";
  font-weight: 700;
  font-size: 24px;
  line-height: 27px;
  color: #051014;
}

.nova-my_profile-service-card-price-container h3 {
  font-family: "poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: right;
  color: #171d1c;
}

.nova-my-profile-service-card-main_container p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #3d3d3d;
  width: 30rem;
}
.nova-my-profile-service-card-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nova-my-profile-service-card-date-container img {
  width: 2.4rem;
  height: 2.4rem;
  object-fit: contain;
}
.nova-my-profile-service-card-date-container h4 {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #3d3d3d;
  margin-left: 2rem;
}

/* ---------Card 0n Hover------------- */
.nova-my-profile-service-card-hover-card-container {
  position: absolute;
  background-color: #ffeff7;
  z-index: 10;
  width: 30rem;
}

/* -------------Media Query--------------- */
@media screen and (max-width: 1440px) {
  .nova-my-profile-service-card-main_container p {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .nova-my_profile-service-card-price-container h3 {
    font-size: 2rem;
  }
  .nova-my-profile-service-card-main_container {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 1200px) {
  .nova-my-profile-service-card-main_container {
    margin-top: 2rem;
  }
}
